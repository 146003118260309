import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";

const CompanyDescriptionCard = ({ companyDescription }) => {
  return (
    <Box
      SX={{
        display: "flex",
        flexDirection: "column",
        width: {
          xs: "100%",
          sm: "100%",
          md: "100%",
          lg: "100%",
          xl: "100%",
        },
        px: {
          xs: 2,
          sm: 2,
          md: 0,
          lg: 0,
          xl: 0,
        },
      }}
    >
      <Typography
        sx={{
          fontSize: {
            xs: "1.2rem",
            sm: "1.5rem",
            md: "1.5rem",
            lg: "1.5rem",
            xl: "1.5rem",
          },
          fontFamily: "Poppins",
          fontWeight: "bold",
          mb: 2,
          color: "#047857",
          textTransform: "uppercase",
        }}
      >
        Company Description
      </Typography>
      <Box
        sx={{
          height: "250px",
          overflow: "auto",
          scrollbarColor: "#047857 #f1f1f1",
          scrollbarWidth: "0.4rem",
          "&::-webkit-scrollbar": {
            width: "0.4rem",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#047857",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            color: "#000000",
            fontSize: "1rem",
            textAlign: "justify",
          }}
        >
          {companyDescription}
        </Typography>
      </Box>
    </Box>
  );
};

export default CompanyDescriptionCard;
