import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";

const controversyDescription = [
  {
    controversyLevel: 0,
    risk: "None",
    description:
      "No impact on the environment and society, and risks to the company are negligible or non-existent.",
  },
  {
    controversyLevel: 1,
    risk: "Low",
    description:
      "Low impact on the environment and society, and risks to the company are minimal or negligible.",
  },
  {
    controversyLevel: 2,
    risk: "Moderate",
    description:
      "Moderate impact on the environment and society, posing moderate business risks to the company. This rating level indicates low frequency of recurrence of incidents and adequate or strong management systems and/or a company response that mitigates further risks.",
  },
  {
    controversyLevel: 3,
    risk: "Significant",
    description:
      "Significant impact on the environment and society, posing significant business risks to the company. This rating level indicates evidence of structural problems in the company due to recurrence of incidents and inadequate implementation or lack of appropriate management systems.",
  },
  {
    controversyLevel: 4,
    risk: "High",
    description:
      "High impact on the environment and society, posing high business risks to the company. This rating level indicates systemic problems within the company, weak management systems and company response, and a recurrence of incidents.",
  },
  {
    controversyLevel: 5,
    risk: "Severe",
    description:
      "Severe impact on the environment and society, posing serious business risks to the company. This category indicates exceptional egregious corporate behavior, relatively high frequency of recurrence of incidents, very poor management of ESG risks, and a demonstrated lack of willingness by the company to address such risks.",
  },
];

const ControversyTooltip = ({ active, payload, controversy }) => {
  if (active && payload && payload.length) {
    return controversyDescription.map((item) => {
      return (
        <>
          {item.controversyLevel === controversy ? (
            <Card>
              <CardContent>
                <Typography
                  sx={{
                    fontFamily: "-apple-system, sans-serif",
                    fontSize: {
                      xs: "0.8rem",
                      sm: "0.6rem",
                      md: "0.7rem",
                      lg: "0.8rem",
                      xl: "0.9rem",
                    },
                    fontWeight: "bold",
                  }}
                >{`Controversy Level: ${item.controversyLevel}`}</Typography>
                <Typography
                  sx={{
                    fontFamily: "-apple-system, sans-serif",
                    fontSize: {
                      xs: "0.8rem",
                      sm: "0.6rem",
                      md: "0.7rem",
                      lg: "0.8rem",
                      xl: "0.9rem",
                    },
                  }}
                >{`Severity: ${item.risk}`}</Typography>
                <Typography
                  sx={{
                    fontFamily: "-apple-system, sans-serif",
                    fontSize: {
                      xs: "0.8rem",
                      sm: "0.6rem",
                      md: "0.7rem",
                      lg: "0.8rem",
                      xl: "0.9rem",
                    },
                  }}
                >{`Description: ${item.description}`}</Typography>
              </CardContent>
            </Card>
          ) : null}
        </>
      );
    });
  }

  return null;
};

export default ControversyTooltip;
