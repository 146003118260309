import React from "react";
import { Box, Typography } from "@mui/material";
import ControveryLegend from "./ControveryLegend";
import RatingLegend from "./RatingLegend";

const ESGDetails = ({
  total_esg,
  environment_score,
  social_score,
  governance_score,
  rating,
  controversy,
  updatedYear,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "column",
          lg: "column",
          xl: "column",
        },
        mt: {
          xs: "1.5rem",
          sm: "1.6rem",
          md: 0,
          lg: 0,
          xl: 0,
        },
        mx: {
          xs: "0.5rem",
          sm: "0.5rem",
          md: "1rem",
          lg: "1rem",
          xl: "1rem",
        },
      }}
    >
      {/*Mobile View*/}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          px: 2,
        }}
      >
        <Box
          sx={{
            display: {
              xs: "flex",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "none",
            },
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: {
                xs: "100%",
                sm: "50%",
                md: "50%",
                lg: "50%",
                xl: "50%",
              },
              mt: {
                xs: "1rem",
                sm: "0rem",
                md: "0rem",
                lg: "0rem",
                xl: "0rem",
              },
            }}
          >
            {/* <Typography
              sx={{
                fontSize: "1.1rem",
                color: "#000000",
                fontFamily: "Poppins",

                display: "flex",
                textAlign: "center",
              }}
            >
              Total ESG Risk Score
            </Typography> */}
            <Typography
              sx={{
                fontSize: "1.5rem",
                color: "#000000",
                fontFamily: "Poppins",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Total ESG Score
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "2.8rem",
                  color:
                    rating === "Excellent"
                      ? "#8884d8"
                      : rating === "Very Good"
                      ? "#0A8A3A"
                      : rating === "Good"
                      ? "#ffc658"
                      : rating === "Satisfactory"
                      ? "#FF3D00"
                      : rating === "Poor"
                      ? "#FF0000"
                      : "#000000",
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {total_esg.toFixed(2)}
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: "1.1rem",
                color: "#000000",
                fontFamily: "Poppins",
                textAlign: "center",
                mb: 1.5,
              }}
            >
              {rating}
            </Typography>
            <RatingLegend rating={rating} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: {
                xs: "100%",
                sm: "50%",
                md: "50%",
                lg: "50%",
                xl: "50%",
              },
              mt: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#DEFFDE",
                width: "100%",
                p: 1,
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  color: "#000000",
                  fontFamily: "Poppins",
                }}
              >
                Environment Risk Score:
              </Typography>
              {environment_score === 0 ? (
                <Typography
                  sx={{
                    fontSize: "1.0rem",
                    color: "#000000",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  -
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: "1.0rem",
                    color: "#000000",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  {environment_score.toFixed(2)}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#DEFFDE",
                width: "100%",
                p: 1,
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  color: "#000000",
                  fontFamily: "Poppins",
                }}
              >
                Social Risk Score:
              </Typography>
              {social_score === 0 ? (
                <Typography
                  sx={{
                    fontSize: "1.0rem",
                    color: "#000000",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  -
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: "1.0rem",
                    color: "#000000",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  {social_score.toFixed(2)}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#DEFFDE",
                width: "100%",
                p: 1,
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  color: "#000000",
                  fontFamily: "Poppins",
                }}
              >
                Governance Risk Score:
              </Typography>
              {governance_score === 0 ? (
                <Typography
                  sx={{
                    fontSize: "1.0rem",
                    color: "#000000",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  -
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: "1.0rem",
                    color: "#000000",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  {governance_score.toFixed(2)}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor:
                  controversy === 1
                    ? "rgba(136, 132, 216, 0.2)"
                    : controversy === 2
                    ? "rgba(130, 202, 157, 0.2)"
                    : controversy === 3
                    ? "rgba(255, 198, 88, 0.2)"
                    : controversy === 4
                    ? "rgba(255, 61, 0, 0.2)"
                    : controversy === 5
                    ? "rgba(255, 0, 0, 0.2)"
                    : "rgba(0, 0, 0, 0.2)",
                width: "100%",
                p: 1,
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  // color:
                  //   controversy === 1
                  //     ? "rgba(136, 132, 216, 1)"
                  //     : controversy === 2
                  //     ? "rgba(130, 202, 157, 1)"
                  //     : controversy === 3
                  //     ? "rgba(255, 198, 88, 1)"
                  //     : controversy === 4
                  //     ? "rgba(255, 61, 0, 1)"
                  //     : controversy === 5
                  //     ? "rgba(255, 0, 0, 1)"
                  //     : "rgba(0, 0, 0, 1)",
                  color:
                    controversy === 1
                      ? "#5F58EE"
                      : controversy === 2
                      ? "#1EB155"
                      : controversy === 3
                      ? "#EBAC33"
                      : controversy === 4
                      ? "#F86336"
                      : controversy === 5
                      ? "#FF0000"
                      : "#000000",
                  fontFamily: "Poppins",
                }}
              >
                Controversy Score:
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.0rem",
                  // color:
                  //   controversy === 1
                  //     ? "rgba(136, 132, 216, 1)"
                  //     : controversy === 2
                  //     ? "rgba(130, 202, 157, 1)"
                  //     : controversy === 3
                  //     ? "rgba(255, 198, 88, 1)"
                  //     : controversy === 4
                  //     ? "rgba(255, 61, 0, 1)"
                  //     : controversy === 5
                  //     ? "rgba(255, 0, 0, 1)"
                  //     : "rgba(0, 0, 0, 1)",
                  color:
                    controversy === 1
                      ? "#5F58EE"
                      : controversy === 2
                      ? "#1EB155"
                      : controversy === 3
                      ? "#EBAC33"
                      : controversy === 4
                      ? "#F86336"
                      : controversy === 5
                      ? "#FF0000"
                      : "#000000",
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                }}
              >
                {controversy}
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: "0.9rem",
                color: "#000000",
                fontFamily: "Poppins",
                fontStyle: "italic",
                color: "#301A1ADE",
              }}
            >
              Last ESG Update: {updatedYear.substring(0, 7)}
            </Typography>
            <Box
              sx={{
                display: "flex",

                alignItems: "center",
                mb: "1rem",
                width: "100%",
              }}
            >
              <ControveryLegend controversyScore={controversy} />
            </Box>
            {/* <Typography
              sx={{
                fontSize: "1.1rem",
                color: "#000000",
                fontFamily: "Poppins",
                fontWeight: "bold",
              }}
            >
              Environment Risk Score
            </Typography>
            {environment_score === 0 ? (
              <Typography
                sx={{
                  fontSize: "1.0rem",
                  color: "#000000",
                  fontFamily: "Poppins",
                }}
              >
                -
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontSize: "1.0rem",
                  color: "#000000",
                  fontFamily: "Poppins",
                }}
              >
                {environment_score.toFixed(2)}
              </Typography>
            )}
            <Typography
              sx={{
                fontSize: "1.1rem",
                color: "#000000",
                fontFamily: "Poppins",
                fontWeight: "bold",
              }}
            >
              Social Risk Score
            </Typography>
            {social_score === 0 ? (
              <Typography
                sx={{
                  fontSize: "1.0rem",
                  color: "#000000",
                  fontFamily: "Poppins",
                }}
              >
                -
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontSize: "1.0rem",
                  color: "#000000",
                  fontFamily: "Poppins",
                }}
              >
                {social_score.toFixed(2)}
              </Typography>
            )}
            <Typography
              sx={{
                fontSize: "1.1rem",
                color: "#000000",
                fontFamily: "Poppins",
                fontWeight: "bold",
              }}
            >
              Governance Risk Score
            </Typography>
            {governance_score === 0 ? (
              <Typography
                sx={{
                  fontSize: "1.0rem",
                  color: "#000000",
                  fontFamily: "Poppins",
                }}
              >
                -
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontSize: "1.0rem",
                  color: "#000000",
                  fontFamily: "Poppins",
                }}
              >
                {governance_score.toFixed(2)}
              </Typography>
            )}
            <Typography
              sx={{
                fontSize: "1.1rem",
                color: "#000000",
                fontFamily: "Poppins",
                fontWeight: "bold",
              }}
            >
              Controversy Score
            </Typography>
            <Typography
              sx={{
                fontSize: "1.0rem",
                color: "#000000",
                fontFamily: "Poppins",
              }}
            >
              {controversy}
            </Typography>
            <Typography
              sx={{
                fontSize: "1.1rem",
                color: "#000000",
                fontFamily: "Poppins",
              }}
            >
              Last ESG Update: {updatedYear.substring(0, 7)}
            </Typography> */}
          </Box>
        </Box>
      </Box>
      {/*Desktop View*/}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "flex",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: {
                xs: "100%",
                sm: "50%",
                md: "50%",
                lg: "50%",
                xl: "50%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#DEFFDE",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "90%",
                },
                p: 1,
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  color: "#000000",
                  fontFamily: "Poppins",
                }}
              >
                Environment Risk Score
              </Typography>
              {environment_score === 0 ? (
                <Typography
                  sx={{
                    fontSize: "1.0rem",
                    color: "#000000",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  -
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: "1.0rem",
                    color: "#000000",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  {environment_score.toFixed(2)}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#DEFFDE",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "90%",
                },
                p: 1,
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  color: "#000000",
                  fontFamily: "Poppins",
                }}
              >
                Social Risk Score
              </Typography>
              {social_score === 0 ? (
                <Typography
                  sx={{
                    fontSize: "1.0rem",
                    color: "#000000",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  -
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: "1.0rem",
                    color: "#000000",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  {social_score.toFixed(2)}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#DEFFDE",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "90%",
                },
                p: 1,
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  color: "#000000",
                  fontFamily: "Poppins",
                }}
              >
                Governance Risk Score
              </Typography>
              {governance_score === 0 ? (
                <Typography
                  sx={{
                    fontSize: "1.0rem",
                    color: "#000000",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  -
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: "1.0rem",
                    color: "#000000",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  {governance_score.toFixed(2)}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor:
                  controversy === 1
                    ? "rgba(136, 132, 216, 0.2)"
                    : controversy === 2
                    ? "rgba(130, 202, 157, 0.2)"
                    : controversy === 3
                    ? "rgba(255, 198, 88, 0.2)"
                    : controversy === 4
                    ? "rgba(255, 61, 0, 0.2)"
                    : controversy === 5
                    ? "rgba(255, 0, 0, 0.2)"
                    : "rgba(0, 0, 0, 0.2)",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "90%",
                },
                p: 1,
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  // color:
                  //   controversy === 1
                  //     ? "rgba(136, 132, 216, 1)"
                  //     : controversy === 2
                  //     ? "rgba(130, 202, 157, 1)"
                  //     : controversy === 3
                  //     ? "rgba(255, 198, 88, 1)"
                  //     : controversy === 4
                  //     ? "rgba(255, 61, 0, 1)"
                  //     : controversy === 5
                  //     ? "rgba(255, 0, 0, 1)"
                  //     : "rgba(0, 0, 0, 1)",
                  color:
                    controversy === 1
                      ? "#5F58EE"
                      : controversy === 2
                      ? "#1EB155"
                      : controversy === 3
                      ? "#EBAC33"
                      : controversy === 4
                      ? "#F86336"
                      : controversy === 5
                      ? "#FF0000"
                      : "#000000",
                  fontFamily: "Poppins",
                }}
              >
                Controversy Score
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.0rem",
                  color:
                    controversy === 1
                      ? "#5F58EE"
                      : controversy === 2
                      ? "#1EB155"
                      : controversy === 3
                      ? "#EBAC33"
                      : controversy === 4
                      ? "#F86336"
                      : controversy === 5
                      ? "#FF0000"
                      : "#000000",
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                }}
              >
                {controversy}
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: "0.9rem",
                color: "#000000",
                fontFamily: "Poppins",
                fontStyle: "italic",
                color: "#301A1ADE",
              }}
            >
              Last ESG Update: {updatedYear.substring(0, 7)}
            </Typography>
            <Box
              sx={{
                display: "flex",

                alignItems: "center",
                mb: "1rem",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "90%",
                },
              }}
            >
              <ControveryLegend controversyScore={controversy} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: {
                xs: "100%",
                sm: "50%",
                md: "50%",
                lg: "50%",
                xl: "50%",
              },
              mt: {
                xs: "1rem",
                sm: "0rem",
                md: "0rem",
                lg: "0rem",
                xl: "0rem",
              },
              mr: {
                xs: "0rem",
                sm: "0rem",
                md: "2rem",
                lg: "2rem",
                xl: "2rem",
              },
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#F5FCF5",
                height: "80%",
                width: "100%",
              }}
            >
              {/* <Typography
                sx={{
                  fontSize: "1.1rem",
                  color: "#000000",
                  fontFamily: "Poppins",

                  textAlign: "center",
                }}
              >
                Total ESG Risk Score
              </Typography> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.3rem",
                    color: "#000000",
                    fontFamily: "Poppins",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Total ESG Score
                </Typography>
                <Typography
                  sx={{
                    fontSize: "2.9rem",
                    color:
                      rating === "Excellent"
                        ? "#8884d8"
                        : rating === "Very Good"
                        ? "#0A8A3A"
                        : rating === "Good"
                        ? "#ffc658"
                        : rating === "Satisfactory"
                        ? "#FF3D00"
                        : rating === "Poor"
                        ? "#FF0000"
                        : "#000000",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {total_esg.toFixed(2)}
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  color: "#000000",
                  fontFamily: "Poppins",
                  textAlign: "center",
                }}
              >
                {rating}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",

                alignItems: "center",
                mb: "1rem",
                mt: "1rem",
                width: "100%",
              }}
            >
              <RatingLegend rating={rating} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ESGDetails;
