import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import { decode } from "html-entities";

const NewsRSSFeed = ({ rss_feed }) => {
  const regex = /<(.|\n)*?>/g;
  return (
    <Box
      sx={{
        display: {
          xs: "flex",
          sm: "flex",
          md: "flex",
          lg: "flex",
          xl: "flex",
        },
        flexDirection: "column",
        width: {
          xs: "100%",
          sm: "100%",
          md: "80%",
          lg: "75%",
          xl: "70%",
        },
        height: "500px",
        overflow: "auto",
        scrollbarColor: "#047857 #f1f1f1",
        scrollbarWidth: "0.4rem",
        "&::-webkit-scrollbar": {
          width: "0.4rem",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#047857",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // height: {
            //   xs: "100%",
            //   sm: "100%",
            //   md: "500px",
            //   lg: "500px",
            //   xl: "500px",
            // },

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {rss_feed.length > 0
            ? rss_feed.map((feed) => {
                return (
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      mb: 2,
                      cursor: "pointer",
                      backgroundColor: "#E4F3E4",
                    }}
                    onClick={() => {
                      window.open(feed.link);
                    }}
                  >
                    <CardContent>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "1.1rem",
                            sm: "1.4rem",
                            md: "1.4rem",
                            lg: "1.4rem",
                            xl: "1.4rem",
                          },
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          "&:hover": {
                            color: "#047857",
                            textDecoration: "underline",
                          },

                          px: 2,
                        }}
                      >
                        {feed.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "1rem",
                            sm: "1rem",
                            md: "1rem",
                            lg: "1rem",
                            xl: "1rem",
                          },
                          fontFamily: "Poppins",
                          // "&:hover": {
                          //   textDecoration: "underline",
                          // },
                          textAlign: "justify",
                          px: 2,
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 3,
                        }}
                      >
                        {decode(feed.summary_detail.replace(regex, ""))}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "1.1rem",
                            sm: "0.8rem",
                            md: "0.8rem",
                            lg: "0.8rem",
                            xl: "0.8rem",
                          },
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          color: "#047857",
                          px: 2,
                        }}
                      >
                        Read More
                      </Typography>
                    </CardContent>
                  </Card>
                );
              })
            : null}
        </Box>
      </Box>
    </Box>
  );
};

export default NewsRSSFeed;
