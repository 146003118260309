import React from "react";
import { Box, Typography } from "@mui/material";

const RatingLegend = ({ rating }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          fontSize: {
            xs: "1rem",
            sm: "1rem",
            md: "1rem",
            lg: "1rem",
            xl: "1.1rem",
          },
          color: "#000000",
          fontFamily: "Poppins",
          textAlign: "center",
          fontWeight: "bold",
          mb: {
            xs: 1,
            sm: 1,
            md: 0.5,
            lg: 0.5,
            xl: 0.5,
          },
        }}
      >
        Rating Legend
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",

          alignItems: "center",
          width: {
            xs: "100%",
            sm: "100%",
            md: "80%",
            lg: "80%",
            xl: "80%",
          },
          height: {
            xs: "1.3rem",
            sm: "1.3rem",
            md: "1.5rem",
            lg: "1.6rem",
            xl: "1.7rem",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "20%",
            height: rating === "Excellent" ? "27px" : "19px",
            // backgroundColor: "#8884d8",
            backgroundColor: "#5F58EE",
            justifyContent: "center",
            alignItems: "center",
            // border: rating === "Excellent" ? "3px solid #06AF7F" : "none",
            border: rating === "Excellent" ? "2px solid #FFFFFF" : "none",
            boxShadow:
              rating === "Excellent" ? "0px 3px 16px #6767675C" : "none",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "0.8rem",
              color: "#FFFFFF",
              fontWeight: rating === "Excellent" ? "bold" : "100",
            }}
          >
            0-10
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "20%",
            height: rating === "Very Good" ? "27px" : "19px",
            // backgroundColor: "#82ca9d",
            backgroundColor: "#1EB155",
            justifyContent: "center",
            alignItems: "center",
            // border: rating === "Very Good" ? "3px solid #06AF7F" : "none",
            border: rating === "Very Good" ? "2px solid #FFFFFF" : "none",
            boxShadow:
              rating === "Very Good" ? "0px 3px 16px #6767675C" : "none",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "0.8rem",
              color: "#FFFFFF",
              fontWeight: rating === "Very Good" ? "bold" : "100",
            }}
          >
            11-20
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "20%",
            height: rating === "Good" ? "27px" : "19px",
            // backgroundColor: "#ffc658",
            backgroundColor: "#EBAC33",
            justifyContent: "center",
            alignItems: "center",
            // border: rating === "Good" ? "3px solid #06AF7F" : "none",
            border: rating === "Good" ? "2px solid #FFFFFF" : "none",
            boxShadow: rating === "Good" ? "0px 3px 16px #6767675C" : "none",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "0.8rem",
              color: "#FFFFFF",
              fontWeight: rating === "Good" ? "bold" : "100",
            }}
          >
            21-30
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "20%",
            height: rating === "Satisfactory" ? "27px" : "19px",
            // backgroundColor: "#FF774D",
            backgroundColor: "#F86336",
            justifyContent: "center",
            alignItems: "center",
            // border: rating === "Satisfactory" ? "3px solid #06AF7F" : "none",
            border: rating === "Satisfactory" ? "2px solid #FFFFFF" : "none",
            boxShadow:
              rating === "Satisfactory" ? "0px 3px 16px #6767675C" : "none",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "0.8rem",
              color: "#FFFFFF",
              fontWeight: rating === "Staisfactory" ? "bold" : "100",
            }}
          >
            31-40
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "20%",
            height: rating === "Poor" ? "27px" : "19px",
            backgroundColor: "#FF0000",
            justifyContent: "center",
            alignItems: "center",
            // border: rating === "Poor" ? "3px solid #06AF7F" : "none",
            border: rating === "Poor" ? "2px solid #FFFFFF" : "none",
            boxShadow: rating === "Poor" ? "0px 3px 16px #6767675C" : "none",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "0.8rem",
              color: "#FFFFFF",
              fontWeight: rating === "Poor" ? "bold" : "100",
            }}
          >
            41-50+
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",

          alignItems: "center",
          width: {
            xs: "100%",
            sm: "100%",
            md: "80%",
            lg: "80%",
            xl: "80%",
          },
          height: {
            xs: "1.3rem",
            sm: "1.3rem",
            md: "1.5rem",
            lg: "1.6rem",
            xl: "1.7rem",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "20%",

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontFamily: "Poppins",
              fontSize: "0.6rem",
            }}
          >
            Excellent
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "20%",

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontFamily: "Poppins",
              fontSize: "0.6rem",
            }}
          >
            V.Good
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "20%",

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontFamily: "Poppins",
              fontSize: "0.6rem",
            }}
          >
            Good
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "20%",

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontFamily: "Poppins",
              fontSize: "0.6rem",
            }}
          >
            Satisfactory
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "20%",

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontFamily: "Poppins",
              fontSize: "0.6rem",
            }}
          >
            Poor
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default RatingLegend;
