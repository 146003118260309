import React from "react";
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const ESGRadarChart = ({ radar_chart_data, highest_controversy, symbol }) => {
  return (
    <ResponsiveContainer aspect={1.5} width="100%" height="60%">
      <RadarChart
        innerRadius={20}
        cx="50%"
        cy="50%"
        height={220}
        outerRadius="90%"
        data={radar_chart_data}
      >
        <PolarGrid gridType="circle" strokeWidth={1} stroke="#000000" />
        <PolarAngleAxis
          dataKey="subject"
          orient="outer"
          tick={{
            fontSize: "0.8rem",
            fill: "#000000",
            fontFamily: "Poppins",
            margin: "2px",
            padding: "2px",
          }}
          tickFormatter={(value) => {
            return value.substring(0, 1);
          }}
        />

        <PolarRadiusAxis
          angle={150}
          domain={[0, 33]}
          axisType="number"
          tick={{
            fontSize: "0.6rem",
            fill: "#000000",
            fontFamily: "Poppins",
          }}
        />
        <Radar
          name={symbol}
          dataKey="score"
          stroke={
            highest_controversy === 1
              ? "#8884d8"
              : highest_controversy === 2
              ? "#0A8A3A"
              : highest_controversy === 3
              ? "#ffc658"
              : highest_controversy === 4
              ? "#FF3D00"
              : highest_controversy === 5
              ? "#FF0000"
              : "#000000"
          }
          fill={
            highest_controversy === 1
              ? "#8884d8"
              : highest_controversy === 2
              ? "#0A8A3A"
              : highest_controversy === 3
              ? "#ffc658"
              : highest_controversy === 4
              ? "#FF3D00"
              : highest_controversy === 5
              ? "#FF0000"
              : "#000000"
          }
          fillOpacity={0.6}
          dot={false}
          strokeWidth={2}
          // strokeLinejoin="round"
          strokeLinecap="round"
          legendType="circle"
        />

        <Tooltip offset={20} />
        <Legend
          wrapperStyle={{
            fontSize: "0.8rem",
            fontFamily: "Poppins",

            position: "relative",
            marginTop: "0.1rem",
          }}
          // onMouseEnter={
          //   handleControversyMouseEnter
          // }
          // onMouseLeave={
          //   handleControversyMouseLeave
          // }
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default ESGRadarChart;
