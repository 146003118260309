import { createSlice } from "@reduxjs/toolkit";

export const companyDetailsSlice = createSlice({
  name: "companyDetails",
  initialState: {
    company_details_data: [],
    trend_chart_data: [],
    company_details_loading: true,
    company_slug: null,
    rss_feed: [],
    search_company_name: null,
  },
  reducers: {
    setCompanyDetailsData: (state, action) => {
      state.company_details_data = action.payload.company_details_data;
    },
    setCompanyDetailSlug: (state, action) => {
      state.company_slug = action.payload.company_slug;
    },
    setCompanyDetailsLoading: (state, action) => {
      state.company_details_loading = action.payload.company_details_loading;
    },
    setTrendChartData: (state, action) => {
      state.trend_chart_data = action.payload.trend_chart_data;
    },
    setRSSFeed: (state, action) => {
      state.rss_feed = action.payload.rss_feed;
    },
    setSearchCompanyName: (state, action) => {
      state.search_company_name = action.payload.search_company_name;
    },
  },
});

export const {
  setCompanyDetailsData,
  setCompanyDetailsLoading,
  setCompanyDetailSlug,
  setTrendChartData,
  setRSSFeed,
  setSearchCompanyName,
} = companyDetailsSlice.actions;

export default companyDetailsSlice.reducer;
