import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Button,
  Pagination,
  PaginationItem,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Checkbox,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import Loading from "./Loading";
import { useSelector, useDispatch } from "react-redux";
// import {
//   Legend,
//   ResponsiveContainer,
//   Tooltip,
//   ComposedChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
// } from "recharts";
import { setFocusedCountry, setFocusedIndice } from "../store/reducers/indices";

import {
  setIndustryFilterValue,
  setRatingFilterValue,
  setESGCFilterValue,
  setChecked,
  setCheckedRating,
} from "../store/reducers/filterList";
import {
  setCompanyDetailsData,
  setCompanyDetailsLoading,
  setTrendChartData,
} from "../store/reducers/companyDetails";
import {
  setStockListdata,
  setStockListLoading,
  setResetFilters,
  setPageNumber,
} from "../store/reducers/stockList";
import {
  setFocusedCountryName,
  setFocusedCountryFlag,
} from "../store/reducers/indices";
import { BiChevronsRight, BiChevronsLeft } from "react-icons/bi";
import UseWindowDimensions from "../service/UseWindowDimensions";
import { CLIENT_BASE_URL, SERVER_TYPE } from "../esg_project_config";
import ESGRadarChart from "./ESGRadarChart";
import { MdExpandMore } from "react-icons/md";

const MobileDashboardContentCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const page_number = useSelector((state) => state.stockList.page_number);
  const country_list = useSelector((state) => state.indiceList.country_list);
  const max_page = useSelector((state) => state.stockList.max_pages);
  // const focused_country = useSelector(
  //   (state) => state.indiceList.focused_country
  // );
  // console.log(focused_country, "The Focused Country");
  // console.log(country_list, "The Country List");
  const stock_list_data = useSelector(
    (state) => state.stockList.stock_list_data
  );
  const stock_list_loading = useSelector(
    (state) => state.stockList.stock_list_loading
  );
  const indice_list = useSelector((state) => state.indiceList.indice_list);
  // console.log(indice_list, "888888");

  // const [checked, setChecked] = useState([0]);
  // const [checkedRating, setCheckedRating] = useState([0]);
  const [openCountryAccordion, setOpenCountryAccordion] = useState(false);

  const focused_indice = useSelector(
    (state) => state.indiceList.focused_indice
  );
  const industry_filter_list = useSelector(
    (state) => state.filterList.industry_filter_list
  );
  const rating_filter_list = useSelector(
    (state) => state.filterList.rating_filter_list
  );
  const selected_industry_filter = useSelector(
    (state) => state.filterList.selected_industry_filter
  );
  const checked = useSelector((state) => state.filterList.checked);
  const checkedRating = useSelector((state) => state.filterList.checkedRating);
  // const selected_rating_filter = useSelector(
  //   (state) => state.filterList.selected_rating_filter
  // );
  const focused_country_name = useSelector(
    (state) => state.indiceList.focused_country_name
  );
  const focused_country_flag = useSelector(
    (state) => state.indiceList.focused_country_flag
  );
  // console.log(focused_country_name, "The Focused Country Name");
  // console.log(focused_country_flag, "The Focused Country Flag");

  const handleIndustryFilterChange = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    // console.log("The Industry target value is ", newChecked);
    dispatch(setChecked({ checked: newChecked }));
    // setChecked(newChecked);
    const apiParam = newChecked.slice(1);
    const quotedParam = apiParam.map((item) => `'${item}'`);
    const joinedParam = quotedParam.join(",");
    // console.log("The param for Industry is ", joinedParam);
    dispatch(setIndustryFilterValue({ selected_industry_filter: joinedParam }));
    // console.log("selected industry filter", selected_industry_filter);
    dispatch(setPageNumber({ page_number: 1 }));
    dispatch(setStockListLoading({ stock_list_loading: true }));
  };
  // console.log("selected industry filter", selected_industry_filter);

  const handleRatingFilterChange = (value) => {
    const currentIndex = checkedRating.indexOf(value);
    const newChecked = [...checkedRating];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    // console.log("The Rating target value is ", newChecked);
    dispatch(setCheckedRating({ checkedRating: newChecked }));
    // setCheckedRating(newChecked);
    const apiParam = newChecked.slice(1);
    const quotedParam = apiParam.map((item) => `'${item}'`);
    const joinedParam = quotedParam.join(",");
    // console.log("The param for Rating is ", joinedParam);
    dispatch(setRatingFilterValue({ selected_rating_filter: joinedParam }));
    dispatch(setPageNumber({ page_number: 1 }));
    dispatch(setStockListLoading({ stock_list_loading: true }));
  };

  const handleTab = (newValue) => {
    // console.log(newValue, "opopopo");
    dispatch(setRatingFilterValue({ selected_rating_filter: null }));
    dispatch(setIndustryFilterValue({ selected_industry_filter: null }));
    dispatch(setESGCFilterValue({ selected_esgc_filter: null }));
    dispatch(setStockListdata({ stock_list_data: [] }));
    dispatch(setChecked({ checked: [0] }));
    dispatch(setCheckedRating({ checkedRating: [0] }));
    dispatch(setStockListLoading({ stock_list_loading: true }));
    // console.log("New Value is ", newValue);
    // dispatch(setFocusedIndice({ focused_indice: newValue }));
    let index_to_be_focused;
    let country_name;
    let country_flag;
    if (newValue !== "all") {
      country_list.filter((country) => {
        if (newValue === country.id) {
          // console.log(country.indices[0].id);
          index_to_be_focused = country.indices[0].id;
          country_name = country.country;
          country_flag = country.country_flag;
        }
      });
    } else {
      index_to_be_focused = newValue;
      country_name = "All";
      country_flag = null;
    }
    // console.log(index_to_be_focused, "ooioioioioi");
    dispatch(setFocusedCountry({ focused_country: newValue }));
    dispatch(setFocusedIndice({ focused_indice: index_to_be_focused }));
    dispatch(setFocusedCountryName({ focused_country_name: country_name }));
    dispatch(setFocusedCountryFlag({ focused_country_flag: country_flag }));
    dispatch(setPageNumber({ page_number: 1 }));
    setOpenCountryAccordion(false);
  };

  const handlePagination = (event, value) => {
    dispatch(setPageNumber({ page_number: value }));
    dispatch(setStockListdata({ stock_list_data: [] }));
    dispatch(setStockListLoading({ stock_list_loading: true }));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleIndiceButton = (value) => {
    dispatch(setRatingFilterValue({ selected_rating_filter: null }));
    dispatch(setIndustryFilterValue({ selected_industry_filter: null }));
    dispatch(setESGCFilterValue({ selected_esgc_filter: null }));
    dispatch(setFocusedIndice({ focused_indice: value }));
    dispatch(setPageNumber({ page_number: 1 }));
    dispatch(setStockListdata({ stock_list_data: [] }));
    dispatch(setChecked({ checked: [0] }));
    dispatch(setCheckedRating({ checkedRating: [0] }));
    dispatch(setStockListLoading({ stock_list_loading: true }));
  };

  const handleDetailPage = (slug, company_name) => {
    dispatch(setCompanyDetailsData({ company_details_data: [] }));
    dispatch(setTrendChartData({ trend_chart_data: [] }));
    dispatch(setCompanyDetailsLoading({ company_details_loading: true }));
  };

  const resetFilters = () => {
    // setChecked([0]);
    // setCheckedRating([0]);
    dispatch(setChecked({ checked: [0] }));
    dispatch(setCheckedRating({ checkedRating: [0] }));
    dispatch(setIndustryFilterValue({ selected_industry_filter: null }));
    dispatch(setRatingFilterValue({ selected_rating_filter: null }));
    dispatch(setPageNumber({ page_number: 1 }));
    dispatch(
      setFocusedCountryName({ focused_country_name: country_list[0].country })
    );
    dispatch(
      setFocusedCountryFlag({
        focused_country_flag: country_list[0].country_flag,
      })
    );
    dispatch(setStockListLoading({ stock_list_loading: true }));
    dispatch(setResetFilters({ reset_filters: true }));
  };

  const { width } = UseWindowDimensions();

  return (
    <Card
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        elevation: 0,
        px: 0.5,
        mx: 0,
        justifyContent: "center",
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2)",
        backgroundColor: "#e6f1e6",
        borderRadius: "2px",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            mb: 2,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "bold",
              fontSize: "1.2rem",
              color: "#000000",

              textAlign: "start",
            }}
          >
            FILTERS
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "bold",
              fontSize: "0.8rem",
              color: "#047857",
              cursor: "pointer",
              "&:hover": {
                color: "#1EB155",
              },
            }}
            onClick={resetFilters}
          >
            Reset
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#e6f1e6",
                borderRadius: "10px",
                elevation: 0,
                flexDirection: "column",
              }}
            >
              <Accordion
                sx={{
                  display: "flex",
                  width: "100%",
                  backgroundColor: "#e6f1e6",
                  borderRadius: "5px",
                  elevation: 0,
                  flexDirection: "column",
                  border: "1px solid #96C196",
                  transition: "all 0.3s ease-in-out",
                }}
                expanded={openCountryAccordion}
              >
                <AccordionSummary
                  expandIcon={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        fontSize: "1.5rem",
                      }}
                    >
                      <MdExpandMore />
                    </Box>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    display: "flex",
                    width: "100%",
                  }}
                  onClick={() => setOpenCountryAccordion(!openCountryAccordion)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Box
                      component="img"
                      src={focused_country_flag}
                      sx={{
                        width: "30px",
                        mr: 2,
                      }}
                    />

                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        textTransform: "none",
                        fontSize: "1rem",
                      }}
                    >
                      {focused_country_name}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Divider />
                  <List
                    sx={{ width: "100%", maxWidth: 360, bgcolor: "#e6f1e6" }}
                  >
                    {country_list.map((country) => {
                      const labelId = `checkbox-list-label-${country.country}`;
                      return (
                        <ListItemButton
                          key={country}
                          role={undefined}
                          dense
                          button
                          onClick={() => {
                            handleTab(country.id);
                          }}
                        >
                          <ListItemIcon>
                            <img
                              alt=" "
                              src={country.country_flag}
                              style={{
                                width: "30px",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={country.country}
                            primaryTypographyProps={{
                              fontSize: "1rem",
                              fontFamily: "Poppins",
                            }}
                          />
                        </ListItemButton>
                      );
                    })}
                    <ListItemButton
                      role={undefined}
                      dense
                      button
                      onClick={() => {
                        handleTab("all");
                      }}
                    >
                      <ListItemText
                        id="All"
                        primary="All"
                        primaryTypographyProps={{
                          fontSize: "1rem",
                          fontFamily: "Poppins",
                        }}
                      />
                    </ListItemButton>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#e6f1e6",
                borderRadius: "10px",
                elevation: 0,
                flexDirection: "column",
              }}
            >
              <Accordion
                sx={{
                  display: "flex",
                  width: "100%",
                  backgroundColor: "#e6f1e6",
                  borderRadius: "5px",
                  elevation: 0,
                  flexDirection: "column",
                  border: "1px solid #96C196",
                  transition: "all 0.3s ease-in-out",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        fontSize: "1.5rem",
                      }}
                    >
                      <MdExpandMore />
                    </Box>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      textTransform: "none",
                      fontSize: "1rem",
                    }}
                  >
                    Select Industry
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Divider />
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "#e6f1e6",
                      maxHeight: "400px",
                      overflow: "auto",
                      scrollbarColor: "#047857 #f1f1f1",
                      scrollbarWidth: "0.4rem",
                      "&::-webkit-scrollbar": {
                        width: "0.4rem",
                      },
                      "&::-webkit-scrollbar-track": {
                        background: "#f1f1f1",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        background: "#047857",
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        background: "#555",
                      },
                    }}
                  >
                    {industry_filter_list.map((industry) => {
                      const labelId = `checkbox-list-label-${industry}`;
                      return (
                        <ListItemButton
                          key={industry}
                          role={undefined}
                          dense
                          button
                          onClick={() => {
                            handleIndustryFilterChange(industry);
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              m: 0,
                              p: 0,
                              minWidth: "0px",
                            }}
                          >
                            <Checkbox
                              style={{ color: "#28C62B" }}
                              edge="start"
                              checked={checked.indexOf(industry) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={industry}
                            primaryTypographyProps={{
                              fontSize: "1rem",
                              fontFamily: "Poppins",
                            }}
                          />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#e6f1e6",
                borderRadius: "10px",
                elevation: 0,
                flexDirection: "column",
              }}
            >
              <Accordion
                sx={{
                  display: "flex",
                  width: "100%",
                  backgroundColor: "#e6f1e6",
                  borderRadius: "5px",
                  elevation: 0,
                  flexDirection: "column",
                  border: "1px solid #96C196",
                  transition: "all 0.3s ease-in-out",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        fontSize: "1.5rem",
                      }}
                    >
                      <MdExpandMore />
                    </Box>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      textTransform: "none",
                      fontSize: "1rem",
                    }}
                  >
                    Select Rating
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Divider />
                  <List
                    sx={{ width: "100%", maxWidth: 360, bgcolor: "#e6f1e6" }}
                  >
                    {rating_filter_list.map((rating) => {
                      const labelId = `checkbox-list-label-${rating}`;
                      return (
                        <ListItemButton
                          key={rating}
                          role={undefined}
                          dense
                          button
                          onClick={() => {
                            handleRatingFilterChange(rating);
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              m: 0,
                              p: 0,
                              minWidth: "0px",
                            }}
                          >
                            <Checkbox
                              edge="start"
                              checked={checkedRating.indexOf(rating) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                              style={{ color: "#28C62B" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={rating}
                            primaryTypographyProps={{
                              fontSize: "1rem",
                              fontFamily: "Poppins",
                            }}
                          />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {indice_list.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: " center",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                {indice_list.map((indice, index) => {
                  return (
                    <Button
                      key={index}
                      onClick={() => handleIndiceButton(indice.id)}
                      variant="outlined"
                      sx={{
                        mx: 1,
                        my: 1,
                        color:
                          focused_indice === indice.id ? "#ffffff" : "#06AF7F",
                        borderColor:
                          focused_indice === indice.id ? null : "#06AF7F",
                        backgroundColor:
                          focused_indice === indice.id ? "#047857" : null,
                        "&:hover": {
                          backgroundColor: "#047857",
                          color: "#ffffff",
                          border: null,
                        },
                        fontWeight: "600",
                        fontFamily: "Poppins",
                      }}
                    >
                      {indice.name}
                    </Button>
                  );
                })}
              </Box>
            ) : null}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {stock_list_loading ? (
                <Loading />
              ) : (
                <>
                  {stock_list_data?.length === 0 ||
                  stock_list_data === "undefined" ? (
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                      }}
                    >
                      No Data Available for this Indice
                    </Typography>
                  ) : (
                    stock_list_data.map((stock, index) => {
                      return (
                        // <Link
                        //   to={`${CLIENT_BASE_URL}esg-risk-ratings/${stock.slug}`}
                        //   onClick={() => {
                        //     console.log(stock.slug, "stock slug");
                        //     console.log(stock.name, "stock name");
                        //     handleDetailPage(stock.slug, stock.name);
                        //   }}
                        // >
                        <Card
                          key={index}
                          component={SERVER_TYPE !== "local" ? Link : null}
                          to={
                            SERVER_TYPE !== "local"
                              ? `${CLIENT_BASE_URL}esg-risk-ratings/${stock.slug}`
                              : null
                          }
                          sx={{
                            my: 2,
                            boxShadow: "0px 14px 16px #D4DECAB3",

                            borderRadius: "10px",
                            cursor: "pointer",
                            textDecoration: "none",
                            "&:hover": {
                              underline: "none",
                              textDecoration: "none",
                            },
                            backgroundColor: "#F5FCF5",
                          }}
                          onClick={() => {
                            if (SERVER_TYPE === "local") {
                              handleDetailPage(stock.slug, stock.name);
                              navigate(`/esg-risk-ratings/${stock.slug}`);
                            }
                          }}
                        >
                          <CardContent>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "flex-start",
                              }}
                            >
                              <Typography
                                sx={{
                                  // fontWeight: "bold",
                                  fontSize: {
                                    xs: "1.4rem",
                                    sm: "1rem",
                                    md: "1rem",
                                    lg: "1rem",
                                    xl: "1rem",
                                  },
                                  color: "#212529",
                                  fontFamily: "Poppins",
                                  alignText: "start",
                                  underline: "none",
                                  textDecoration: "none",
                                  "&:hover": {
                                    underline: "none",
                                    textDecoration: "none",
                                  },
                                  fontWeight: "600",
                                  px: 1,
                                }}
                              >
                                {stock.name} ({stock.symbol})
                              </Typography>
                              {/* <Divider
                                sx={{
                                  borderBottomWidth: 1,
                                  borderBottomColor: "#D4DECAB3",
                                  width: "100%",
                                  display: "flex",
                                }}
                              /> */}
                            </Box>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                xl={3}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <ESGRadarChart
                                  radar_chart_data={stock.radar_chart_data}
                                  highest_controversy={
                                    stock.highest_controversy
                                  }
                                  symbol={stock.symbol}
                                />
                                {stock.radar_chart_data[0].score === 0 &&
                                stock.radar_chart_data[1].score === 0 &&
                                stock.radar_chart_data[2].score === 0 ? (
                                  <Box
                                    sx={{
                                      mt: {
                                        xs: "5.5rem",
                                        sm: 0,
                                        md: 0,
                                        lg: 0,
                                        xl: 0,
                                      },
                                      mb: {
                                        xs: "-4rem",
                                        sm: 0,
                                        md: 0,
                                        lg: 0,
                                        xl: 0,
                                      },
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontFamily: "Poppins",
                                        fontSize: {
                                          xs: "0.8rem",
                                          sm: "0.8rem",
                                          md: "1rem",
                                          lg: "1rem",
                                          xl: "1rem",
                                        },
                                        color: "#95999E",
                                        underline: "none",
                                        textDecoration: "none",
                                        "&:hover": {
                                          underline: "none",
                                          textDecoration: "none",
                                        },
                                      }}
                                    >
                                      Score Bifurcation Not Available
                                    </Typography>
                                  </Box>
                                ) : null}
                              </Grid>
                              {/* <Grid
                                item
                                xs="none"
                                sm="none"
                                md={6}
                                lg={6}
                                xl={6}
                                sx={{
                                  display: {
                                    xs: "none",
                                    sm: "none",
                                    md: "flex",
                                    lg: "flex",
                                    xl: "flex",
                                  },
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {Object.keys(stock.esg_vs_stock).length > 0 ? (
                                  <ResponsiveContainer
                                    className="watermark"
                                    aspect={1.7}
                                    width="100%"
                                    height="100%"
                                  >
                                    <ComposedChart
                                      data={stock.esg_vs_stock.data_point}
                                    >
                                      <XAxis
                                        dataKey="date"
                                        tickFormatter={(value) => {
                                          return value.substring(0, 7);
                                        }}
                                        tickCount={
                                          Object.keys(stock.esg_vs_stock).length
                                        }
                                        // label={{
                                        //   value: "Date",
                                        //   style: { textAnchor: "middle" },

                                        //   position: "bottom",
                                        //   offset: -2,
                                        // }}
                                      />
                                      <YAxis
                                        yAxisId="left-axis"
                                        reversed={true}
                                        label={{
                                          value: "ESG Score",
                                          style: {
                                            textAnchor: "middle",
                                          },
                                          angle: -90,
                                          position: "left",
                                          offset: -2,
                                        }}
                                      />
                                      <YAxis
                                        yAxisId="right-axis"
                                        orientation="right"
                                        label={{
                                          value: `Share Price (${stock.currency})`,
                                          style: {
                                            textAnchor: "middle",
                                          },
                                          angle: 90,
                                          position: "right",
                                          offset: -2,
                                        }}
                                      />
                                      <Tooltip
                                        formatter={(value) => {
                                          return value.toFixed(2);
                                        }}
                                        labelFormatter={(value) => {
                                          return value.substring(0, 7);
                                        }}
                                      />
                                      <Legend />
                                      <CartesianGrid stroke="#f5f5f5" />
                                      <Line
                                        yAxisId="left-axis"
                                        type="number"
                                        name="Total ESG"
                                        dataKey="total_esg"
                                        stroke="#ff7300"
                                        reversed={true}
                                      />
                                      <Line
                                        yAxisId="right-axis"
                                        type="monotone"
                                        name="Stock Closing Price"
                                        dataKey="stock_closing_price"
                                        stroke="#413ea0"
                                      />
                                    </ComposedChart>
                                  </ResponsiveContainer>
                                ) : null}
                              </Grid> */}
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                xl={3}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  mt:
                                    stock.radar_chart_data[0].score === 0 &&
                                    stock.radar_chart_data[1].score === 0 &&
                                    stock.radar_chart_data[2].score === 0
                                      ? {
                                          xs: 0,
                                          sm: 0,
                                          md: 0,
                                          lg: 0,
                                          xl: 0,
                                        }
                                      : {
                                          xs: 4,
                                          sm: 4,
                                          md: 0,
                                          lg: 0,
                                          xl: 0,
                                        },
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",

                                    width: "80%",
                                    height: {
                                      xs: "100%",
                                      sm: "100%",
                                      md: "90%",
                                      lg: "90%",
                                      xl: "90%",
                                    },
                                    borderRadius: "2px",
                                    my: {
                                      xs: "0.8rem",
                                      sm: "0.8rem",
                                      md: "0rem",
                                      lg: "0rem",
                                      xl: "0rem",
                                    },
                                    py: {
                                      xs: "0.8rem",
                                      sm: "0.8rem",
                                      md: "0rem",
                                      lg: "0rem",
                                      xl: "0rem",
                                    },
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "1.6rem",
                                      color: "#1D2024",
                                      fontFamily: "Poppins",
                                      underline: "none",

                                      textDecoration: "none",
                                      "&:hover": {
                                        underline: "none",
                                        textDecoration: "none",
                                      },
                                    }}
                                  >
                                    ESG Score
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "2.5rem",

                                      fontFamily: "Poppins",
                                      underline: "none",

                                      textDecoration: "none",
                                      "&:hover": {
                                        underline: "none",
                                        textDecoration: "none",
                                      },
                                      color:
                                        stock.rating === "Excellent"
                                          ? "#8884d8"
                                          : stock.rating === "Very Good"
                                          ? "#0A8A3A"
                                          : stock.rating === "Good"
                                          ? "#ffc658"
                                          : stock.rating === "Satisfactory"
                                          ? "#FF3D00"
                                          : stock.rating === "Poor"
                                          ? "#FF0000"
                                          : "#000000",
                                    }}
                                  >
                                    {stock.total_esg.toFixed(2)}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "1rem",
                                      color: "#000000",
                                      fontFamily: "Poppins",
                                      underline: "none",

                                      textDecoration: "none",
                                      "&:hover": {
                                        underline: "none",
                                        textDecoration: "none",
                                      },
                                    }}
                                  >
                                    {stock.rating}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "0.6rem",
                                      color: "#95999E",
                                      fontFamily: "Poppins",
                                      underline: "none",
                                      textDecoration: "none",
                                      "&:hover": {
                                        underline: "none",
                                        textDecoration: "none",
                                      },
                                    }}
                                  >
                                    Last ESG Update:{" "}
                                    {stock.registered_year.substring(0, 7)}
                                  </Typography>
                                  {/* <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "1rem",
                                      color: "#047857",
                                      fontFamily: "Poppins",
                                      alignText: "center",
                                      underline: "none",
                                      textTransform: "uppercase",
                                      px: 2,
                                      pt: {
                                        xs: 2,
                                        sm: 2,
                                        md: 4,
                                        lg: 5,
                                        xl: 5,
                                      },
                                    }}
                                  >
                                    Know More
                                  </Typography> */}
                                </Box>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                        // </Link>
                      );
                    })
                  )}
                </>
              )}
            </Box>
            {stock_list_data?.length === 0 ? null : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Pagination
                  count={max_page}
                  variant="outlined"
                  shape="rounded"
                  size="large"
                  siblingCount={width < 540 ? 0 : 1}
                  boundaryCount={width < 540 ? 0 : 1}
                  width="100%"
                  sx={{
                    mt: 2,
                    mb: 2,

                    " .MuiPaginationItem-root": {
                      color: "#047857",
                      borderColor: "#047857",
                      width: "2.5rem",

                      "&.Mui-selected": {
                        color: "#ffffff",
                        backgroundColor: "#047857",
                      },
                      "&:hover": {
                        color: "#ffffff",
                        backgroundColor: "#047857",
                      },
                    },
                  }}
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{
                        previous: BiChevronsLeft,
                        next: BiChevronsRight,
                      }}
                      {...item}
                    />
                  )}
                  page={page_number}
                  onChange={handlePagination}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MobileDashboardContentCard;
