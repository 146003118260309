import React from "react";
import {
  Box,
  Card,
  CardContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";

const ProductAreas = ({
  palm_oil,
  controversial_weapons,
  gambling,
  nuclear,
  fur_leather,
  alcoholic,
  gmo,
  catholic,
  animal_testing,
  tobacco,
  coal,
  pesticides,
  adult,
  small_arms,
  miltiary_contract,
}) => {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: {
            xs: "1.2rem",
            sm: "1.5rem",
            md: "1.5rem",
            lg: "1.5rem",
            xl: "1.5rem",
          },
          fontFamily: "Poppins",
          fontWeight: "bold",
          color: "#047857",
          mb: 2,
          textTransform: "uppercase",
        }}
      >
        Product Involvement Areas
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0.5rem",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            display: "flex",
            height: {
              xs: "100%",
              sm: "100%",
              md: "510px",
              lg: "510px",
              xl: "510px",
            },
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "80%",
              xl: "100%",
            },
            overflow: "auto",
            scrollbarColor: "#047857 #f1f1f1",
            scrollbarWidth: "0.4rem",
            "&::-webkit-scrollbar": {
              width: "0.4rem",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#047857",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
            backgroundColor: "#F5FCF5",
          }}
        >
          <Table
            sx={{
              minWidth: "100%",
            }}
          >
            <TableHead
              sx={{
                backgroundColor: "#E6F1E6",
              }}
            >
              <TableRow
                sx={{
                  height: {
                    xs: "1.5rem",
                    sm: "1.5rem",
                    md: "1.5rem",
                    lg: "1.5rem",
                    xl: "1.5rem",
                  },
                }}
              >
                <TableCell
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: {
                      xs: "0.7rem",
                      sm: "0.8rem",
                      md: "0.8rem",
                      lg: "0.8rem",
                      xl: "0.8rem",
                    },

                    width: "50%",
                  }}
                >
                  Products and Activities
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: {
                      xs: "0.7rem",
                      sm: "0.8rem",
                      md: "0.8rem",
                      lg: "0.8rem",
                      xl: "0.8rem",
                    },
                    width: "50%",
                    // pr: {
                    //   xs: 0,
                    //   sm: 0,
                    //   md: "11rem",
                    //   lg: "10rem",
                    //   xl: "23rem",
                    // },
                  }}
                >
                  Significant Involvement
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                fontFamily: "Poppins",
              }}
            >
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                    }}
                  >
                    Palm Oil
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {palm_oil ? "Yes" : "No"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                    }}
                  >
                    Controversial Weapons
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      textAlign: "center",
                    }}
                  >
                    {controversial_weapons ? "Yes" : "No"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    Gambling
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {gambling ? "Yes" : "No"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    Nuclear
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {nuclear ? "Yes" : "No"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    Fur Leather
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {fur_leather ? "Yes" : "No"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    Alcohol
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {alcoholic ? "Yes" : "No"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    GMO
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {gmo ? "Yes" : "No"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    Catholic
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {catholic ? "Yes" : "No"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    Animal Testing
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {animal_testing ? "Yes" : "No"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    Tobacco
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {tobacco ? "Yes" : "No"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    Coal
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {coal ? "Yes" : "No"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    Pesticides
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {pesticides ? "Yes" : "No"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    Adult
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {adult ? "Yes" : "No"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    Small Arms
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {small_arms ? "Yes" : "No"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    Military Contract
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "0.7rem",
                        sm: "0.8rem",
                        md: "0.8rem",
                        lg: "0.8rem",
                        xl: "0.8rem",
                      },
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {miltiary_contract ? "Yes" : "No"}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ProductAreas;
