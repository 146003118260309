import React from "react";
import { Box, AppBar, Toolbar, Button, Typography } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import ESGLogo from "../assets/yodaplus-ESG-Logo.svg";
import { CLIENT_BASE_URL, SERVER_TYPE } from "../esg_project_config";
import { HiHome } from "react-icons/hi";
import SimpleTooltip from "./SimpleTootip";

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <AppBar
      sx={{
        backgroundColor: "#F3F8F3",
        py: 1,
        elevation: 5,
        position: "sticky",
        width: "100%",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: { xs: 1, sm: 1, md: 5, lg: 5 },
          width: "100%",
        }}
        disableGutters
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                window.open("https://yodaplus.com/");
              }}
              startIcon={
                <HiHome
                  style={{
                    color: "#FFFA9C",
                  }}
                />
              }
              size="medium"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
                backgroundColor: "#047857",
                color: "#fff",
                transition: "all 0.3s linear",
                "&:hover": {
                  backgroundColor: "#10b981",
                },
                border: "1px solid #10b981",

                // p: { xs: 1, sm: 1, md: 1, lg: 2, xl: 2 },
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
                textAlign: "center",
                fontFamily: "Poppins",
                textTransform: "none",
                fontWeight: "100",
              }}
            >
              Home
            </Button>
          </Box> */}
          <Box>
            <Box
              component="img"
              src={ESGLogo}
              sx={{
                display: "flex",
                flexDirection: "row",
                height: {
                  xs: "1.6rem",
                  sm: "1.7rem",
                  md: "1.8rem",
                  lg: "2rem",
                  xl: "2.2rem",
                },
                mb: {
                  xs: "0.2rem",
                  sm: "0.3rem",
                  md: "0.4rem",
                  lg: "0.4rem",
                  xl: "0.4rem",
                },
                cursor: "pointer",
              }}
              onClick={() => {
                if (SERVER_TYPE !== "local") {
                  window.location.href = `${CLIENT_BASE_URL}`;
                } else {
                  navigate("/");
                }
              }}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <a href="https://www.yahoo.com/?ilc=401" target="_blank">
                {" "}
                <img
                  src="https://poweredby.yahoo.com/poweredby_yahoo_h_purple.png"
                  width="100"
                />{" "}
              </a>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "flex",
              xl: "flex",
            },
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "1rem",
              px: { xs: 1, sm: 2, md: 2, lg: 2, xl: 2 },
              color: "#000",
              underline: "none",
              transition: "all 0.3s linear",
              "&:hover": {
                color: "#047857",
                textDecoration: "none",
              },
              cursor: "pointer",
            }}
            component={Link}
            to="https://yodaplus.com/about.html"
          >
            About Us
          </Typography>
          <SimpleTooltip
            content={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  p: 1,
                  maxWidth: "200px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    px: { xs: 1, sm: 2, md: 2, lg: 2, xl: 2 },
                    color: "#000",
                    underline: "none",
                    transition: "all 0.3s linear",
                    "&:hover": {
                      color: "#047857",
                      textDecoration: "none",
                    },
                    cursor: "pointer",
                    mb: 2,
                  }}
                  component={Link}
                  to="https://yodaplus.com/enterprise-data-management.html"
                >
                  Enterprise Data Management
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    px: { xs: 1, sm: 2, md: 2, lg: 2, xl: 2 },
                    color: "#000",
                    underline: "none",
                    transition: "all 0.3s linear",
                    "&:hover": {
                      color: "#047857",
                      textDecoration: "none",
                    },
                    cursor: "pointer",
                    mb: 2,
                  }}
                  component={Link}
                  to="https://yodaplus.com/blockchain.html"
                >
                  Blockchain
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    px: { xs: 1, sm: 2, md: 2, lg: 2, xl: 2 },
                    color: "#000",
                    underline: "none",
                    transition: "all 0.3s linear",
                    "&:hover": {
                      color: "#047857",
                      textDecoration: "none",
                    },
                    cursor: "pointer",
                    mb: 2,
                  }}
                  component={Link}
                  to="https://yodaplus.com/capital-markets-technology.html"
                >
                  Financial Technology
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    px: { xs: 1, sm: 2, md: 2, lg: 2, xl: 2 },
                    color: "#000",
                    underline: "none",
                    transition: "all 0.3s linear",
                    "&:hover": {
                      color: "#047857",
                      textDecoration: "none",
                    },
                    cursor: "pointer",
                  }}
                  component={Link}
                  to="https://yodaplus.com/e-commerce.html"
                >
                  eCommerce
                </Typography>
              </Box>
            }
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "1rem",
                px: { xs: 1, sm: 2, md: 2, lg: 2, xl: 2 },
                color: "#000",
                underline: "none",
                transition: "all 0.3s linear",
                "&:hover": {
                  color: "#047857",
                },
                cursor: "pointer",
              }}
            >
              Services
            </Typography>
          </SimpleTooltip>

          <SimpleTooltip
            content={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  p: 1,
                  maxWidth: "200px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    px: { xs: 1, sm: 2, md: 2, lg: 2, xl: 2 },
                    color: "#000",
                    underline: "none",
                    transition: "all 0.3s linear",
                    "&:hover": {
                      color: "#047857",
                      textDecoration: "none",
                    },
                    cursor: "pointer",
                    mb: 2,
                  }}
                  component={Link}
                  to="https://yodaplus.com/multisig-wallet.html"
                >
                  Yplusvault
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    px: { xs: 1, sm: 2, md: 2, lg: 2, xl: 2 },
                    color: "#000",
                    underline: "none",
                    transition: "all 0.3s linear",
                    "&:hover": {
                      color: "#047857",
                      textDecoration: "none",
                    },
                    cursor: "pointer",
                    mb: 2,
                  }}
                  component={Link}
                  to="https://yodaplus.com/asset-tokenization.html"
                >
                  Asset Tokenization
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    px: { xs: 1, sm: 2, md: 2, lg: 2, xl: 2 },
                    color: "#000",
                    underline: "none",
                    transition: "all 0.3s linear",
                    "&:hover": {
                      color: "#047857",
                      textDecoration: "none",
                    },
                    cursor: "pointer",
                    mb: 2,
                  }}
                  component={Link}
                  to="https://yodaplus.com/defi-lending-pool.html"
                >
                  Defi Lending Pool
                </Typography>
              </Box>
            }
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "1rem",

                px: { xs: 1, sm: 2, md: 2, lg: 2, xl: 2 },
                color: "#000",
                underline: "none",
                transition: "all 0.3s linear",
                "&:hover": {
                  color: "#047857",
                },
                cursor: "pointer",
              }}
            >
              Products
            </Typography>
          </SimpleTooltip>
          <SimpleTooltip
            content={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  p: 1,
                  maxWidth: "200px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    px: { xs: 1, sm: 2, md: 2, lg: 2, xl: 2 },
                    color: "#000",
                    underline: "none",
                    transition: "all 0.3s linear",
                    "&:hover": {
                      color: "#047857",
                      textDecoration: "none",
                    },
                    cursor: "pointer",
                    mb: 2,
                  }}
                  component={Link}
                  to="https://yodaplus.com/blog/"
                >
                  Blogs
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    px: { xs: 1, sm: 2, md: 2, lg: 2, xl: 2 },
                    color: "#000",
                    underline: "none",
                    transition: "all 0.3s linear",
                    "&:hover": {
                      color: "#047857",
                      textDecoration: "none",
                    },
                    cursor: "pointer",
                    mb: 2,
                  }}
                  component={Link}
                  to="https://yodaplus.com/case-studies/"
                >
                  Case Studies
                </Typography>
              </Box>
            }
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "1rem",

                px: { xs: 1, sm: 2, md: 2, lg: 2, xl: 2 },
                color: "#000",
                underline: "none",
                transition: "all 0.3s linear",
                "&:hover": {
                  color: "#047857",
                },
                cursor: "pointer",
              }}
            >
              Resources
            </Typography>
          </SimpleTooltip>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              window.open("https://yodaplus.com/");
            }}
            startIcon={
              <HiHome
                style={{
                  color: "#FFFA9C",
                }}
              />
            }
            size="medium"
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              backgroundColor: "#047857",
              color: "#fff",
              transition: "all 0.3s linear",
              "&:hover": {
                backgroundColor: "#10b981",
              },
              border: "1px solid #10b981",

              // p: { xs: 1, sm: 1, md: 1, lg: 2, xl: 2 },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              textAlign: "center",
              fontFamily: "Poppins",
              textTransform: "none",
              fontWeight: "100",
            }}
          >
            Home
          </Button>
        </Box>
        {/* <Button
          variant="outlined"
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            color: "#000000",
            backgroundColor: "rgba(0, 180, 127, .1)",
            border: "2px solid #00B47F",
            transition: "all 0.3s linear",
            "&:hover": {
              backgroundColor: "rgba(0, 180, 127, 1)",
              color: "#fff",
            },
            marginRight: {
              xs: "1rem",
              sm: "2rem",
              md: "3rem",
              lg: "4rem",
              xl: "4rem",
            },
          }}
        >
          Contact
        </Button> */}
        <Button
          variant="contained"
          onClick={() => {
            window.open("https://yodaplus.com/");
          }}
          startIcon={
            <HiHome
              style={{
                color: "#FFFA9C",
              }}
            />
          }
          size="medium"
          sx={{
            display: {
              xs: "flex",
              sm: "flex",
              md: "none",
              lg: "none",
              xl: "none",
            },
            backgroundColor: "#047857",
            color: "#fff",
            transition: "all 0.3s linear",
            "&:hover": {
              backgroundColor: "#10b981",
            },
            border: "1px solid #10b981",

            // p: { xs: 1, sm: 1, md: 1, lg: 2, xl: 2 },
            width: {
              xs: "25%",
              sm: "25%",
              md: "100%",
              lg: "100%",
              xl: "100%",
            },
            textAlign: "center",
            fontFamily: "Poppins",
            textTransform: "none",
            fontWeight: "100",
          }}
        >
          Home
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
