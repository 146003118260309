import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { setCompanyDetailSlug } from "../../store/reducers/companyDetails";
import { useSelector, useDispatch } from "react-redux";
import { CLIENT_BASE_URL, SERVER_TYPE } from "../../esg_project_config";

const PeerRiskScore = ({ industry, peer_ratings }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: {
          xs: "100%",
          sm: "100%",
          md: "100%",
          lg: "100%",
          xl: "100%",
        },
        mt: {
          xs: "1rem",
          sm: "1rem",
          md: 0,
          lg: 0,
          xl: 0,
        },
        mx: 0,
        px: {
          xs: 0,
          sm: 0,
          md: 6,
          lg: 6,
          xl: 6,
        },
        backgroundColor: "#F5FCF5",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
            lg: "row",
            xl: "row",
          },
          justifyContent: "flex-start",
          alignItems: {
            xs: "flex-start",
            sm: "flex-start",
            md: "center",
            lg: "center",
            xl: "center",
          },
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "1.2rem",
              sm: "1.5rem",
              md: "1.5rem",
              lg: "1.5rem",
              xl: "1.5rem",
            },
            fontFamily: "Poppins",
            fontWeight: "bold",
            textAlign: "center",
            color: "#047857",
            textTransform: "uppercase",
            mb: {
              xs: 0,
              sm: 0,
              md: 2,
              lg: 2,
              xl: 2,
            },
          }}
        >
          ESG Risk Score for Peers
        </Typography>
        <Typography
          sx={{
            fontFamily: "Poppins",
            color: "#000000",
            ml: {
              xs: 0,
              sm: 0,
              md: 2,
              lg: 2,
              xl: 2,
            },
            mb: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2,
            },
            textAlign: "center",
          }}
        >
          ({industry})
        </Typography>
      </Box>
      <Box>
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: "#F5FCF5",
          }}
        >
          <Table sx={{ minWidth: "100%" }}>
            <TableHead
              sx={{
                backgroundColor: "#E6F1E6",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    fontFamily: "Poppins",
                  }}
                >
                  Company Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Poppins",
                  }}
                >
                  Total ESG Risk Score
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Poppins",
                  }}
                >
                  E
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Poppins",
                  }}
                >
                  S
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Poppins",
                  }}
                >
                  G
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {peer_ratings.map((peer) => {
                return (
                  // <Link
                  //   to={`${CLIENT_BASE_URL}esg-risk-ratings/${peer.slug}`}
                  // >
                  <TableRow
                    key={peer.company_name}
                    component={SERVER_TYPE !== "local" ? Link : null}
                    to={
                      SERVER_TYPE !== "local"
                        ? `${CLIENT_BASE_URL}esg-risk-ratings/${peer.slug}`
                        : null
                    }
                    underline="none"
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      cursor: "pointer",
                      textDecoration: "none",
                      "&:hover": {
                        underline: "none",
                        textDecoration: "none",
                      },
                    }}
                    onClick={() => {
                      if (SERVER_TYPE === "local") {
                        navigate(`/esg-risk-ratings/${peer.slug}`, {
                          replace: true,
                        });
                        // window.location.reload();
                      }
                      // console.log(peer.id, "stock id");
                      // dispatch(
                      //   setCompanyDetailSlug({ company_slug: peer.slug })
                      // );
                      // let new_company_name = peer.name
                      //   .replace(/[., ]+/g, "")
                      //   .toLowerCase();
                      // console.log(new_company_name, "new company name");
                      // navigate(`/esg-risk-ratings/${peer.slug}`, {
                      //   replace: true,
                      // });
                      // window.location.href = `${CLIENT_BASE_URL}esg-risk-ratings/${peer.slug}`;
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          underline: "none",
                          "&:hover": {
                            underline: "none",
                            textDecoration: "none",
                          },
                          fontWeight: "600",
                        }}
                      >
                        {peer.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          underline: "none",
                          color: "#B7AC1D",
                          fontWeight: "600",
                          "&:hover": {
                            underline: "none",
                            textDecoration: "none",
                          },
                        }}
                      >
                        {peer.symbol}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontFamily: "Poppins",
                        underline: "none",
                        fontWeight: "600",
                        "&:hover": {
                          underline: "none",
                          textDecoration: "none",
                        },
                      }}
                    >
                      {peer.total_esg.toFixed(2)}
                    </TableCell>
                    {peer.environment_score === 0 ? (
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Poppins",
                          underline: "none",
                          fontWeight: "600",
                          "&:hover": {
                            underline: "none",
                            textDecoration: "none",
                          },
                        }}
                      >
                        -
                      </TableCell>
                    ) : (
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Poppins",
                          underline: "none",
                          fontWeight: "600",
                          "&:hover": {
                            underline: "none",
                            textDecoration: "none",
                          },
                        }}
                      >
                        {peer.environment_score.toFixed(2)}
                      </TableCell>
                    )}
                    {peer.social_score === 0 ? (
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Poppins",
                          underline: "none",
                          fontWeight: "600",
                          "&:hover": {
                            underline: "none",
                            textDecoration: "none",
                          },
                        }}
                      >
                        -
                      </TableCell>
                    ) : (
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Poppins",
                          underline: "none",
                          fontWeight: "600",
                          "&:hover": {
                            underline: "none",
                            textDecoration: "none",
                          },
                        }}
                      >
                        {peer.social_score.toFixed(2)}
                      </TableCell>
                    )}
                    {peer.governance_score === 0 ? (
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Poppins",
                          underline: "none",
                          fontWeight: "600",
                          "&:hover": {
                            underline: "none",
                            textDecoration: "none",
                          },
                        }}
                      >
                        -
                      </TableCell>
                    ) : (
                      <TableCell
                        align="center"
                        sx={{
                          fontFamily: "Poppins",
                          underline: "none",
                          fontWeight: "600",
                          "&:hover": {
                            underline: "none",
                            textDecoration: "none",
                          },
                        }}
                      >
                        {peer.governance_score.toFixed(2)}
                      </TableCell>
                    )}
                  </TableRow>
                  // </Link>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default PeerRiskScore;
