import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import controversyData from "../../service/controversyDescription.json";
const ControversyFlip = ({ controversyLevel }) => {
  return (
    <Card>
      <CardContent>
        <Typography
          sx={{
            fontFamily: "-apple-system, sans-serif",
            fontSize: {
              xs: "0.9rem",
              sm: "1.1rem",
              md: "1.1rem",
              lg: "1.2rem",
              xl: "1.3rem",
            },
            fontWeight: "bold",
          }}
        >{`Controversy Level: ${controversyLevel}`}</Typography>
        <Typography
          sx={{
            fontFamily: "-apple-system, sans-serif",
            fontSize: {
              xs: "0.9rem",
              sm: "1.1rem",
              md: "1.1rem",
              lg: "1.2rem",
              xl: "1.3rem",
            },
          }}
        >{`Severity: ${controversyData.controversyDescription[controversyLevel].risk}`}</Typography>
        <Typography
          sx={{
            fontFamily: "-apple-system, sans-serif",
            fontSize: {
              xs: "0.9rem",
              sm: "1.1rem",
              md: "1.1rem",
              lg: "1.2rem",
              xl: "1.3rem",
            },
          }}
        >{`Description: ${controversyData.controversyDescription[controversyLevel].description}`}</Typography>
      </CardContent>
    </Card>
  );
};

export default ControversyFlip;
