import React from "react";
import { Route, Routes } from "react-router-dom";
import ESGDataComparison from "./pages/ESGDataComparison";
import ESGCompanyDetail from "./pages/ESGCompanyDetail";
import ESGDashboard from "./pages/ESGDashboard";
import ErrorPage from "./pages/ErrorPage";

const ESGRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ESGDashboard />} />
        <Route path="/esg-risk-ratings/:slug" element={<ESGCompanyDetail />} />
        <Route path="/comparison" element={<ESGDataComparison />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
};

export default ESGRoutes;
