import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Tooltip as MuiTooltip,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setCompanyDetailsData,
  setCompanyDetailsLoading,
  setTrendChartData,
  setRSSFeed,
  setSearchCompanyName,
} from "../store/reducers/companyDetails";
import {
  getCompanyDetails,
  getRSSFeed,
} from "../service/API/CompanyDetailsAPI";
import Loading from "../components/Loading";
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Helmet } from "react-helmet";
import ControversyTooltip from "../components/ControversyTooltip";
import ESGSharePriceChart from "../components/ESGSharePriceChart";
import ESGDetails from "../components/detailPageComponents/ESGDetails";
import ProductAreas from "../components/detailPageComponents/ProductAreas";
import PeerRiskScore from "../components/detailPageComponents/PeerRiskScore";
import NewsRSSFeed from "../components/detailPageComponents/NewsRSSFeed";
import ControversyFlip from "../components/detailPageComponents/ControversyFlip";
import CompanyDescriptionCard from "../components/detailPageComponents/CompanyDescriptionCard";
import UFOLogo from "../assets/ufo-green.png";
import { CLIENT_BASE_URL } from "../esg_project_config";
import ErrorPage from "./ErrorPage";
import SearchBar from "../components/SearchBar";
import { BiChevronLeft } from "react-icons/bi";
import ESGRadarChart from "../components/ESGRadarChart";

const ESGCompanyDetail = () => {
  const [loading, setLoading] = useState(true);
  const [toggleControversy, setToggleControversy] = useState(false);
  const [pageNotFound, setPageNotFound] = useState(false);
  const { slug } = useParams();
  // console.log("The Company ID:", id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // dispatch(setCompanyDetailId({ company_slug: id }));
  const company_details_data = useSelector(
    (state) => state.companyDetails.company_details_data
  );
  const company_details_loading = useSelector(
    (state) => state.companyDetails.company_details_loading
  );
  const trend_chart_data = useSelector(
    (state) => state.companyDetails.trend_chart_data
  );
  const rss_feed = useSelector((state) => state.companyDetails.rss_feed);
  const search_company_name = useSelector(
    (state) => state.companyDetails.search_company_name
  );
  const company_slug = useSelector(
    (state) => state.companyDetails.company_slug
  );
  useEffect(() => {
    if (slug !== "undefined" && slug !== null) {
      getCompanyDetails(slug)
        .then((response) => {
          if (response.data.detail_value.length === 0) {
            dispatch(
              setSearchCompanyName({
                search_company_name: response.data.company_name,
              })
            );

            setLoading(false);
          } else {
            // console.log(response.data.detail_value[0], "oooooooooooooo");
            dispatch(
              setSearchCompanyName({
                search_company_name: response.data.company_name,
              })
            );
            dispatch(
              setCompanyDetailsData({
                company_details_data: response.data.detail_value[0],
              })
            );
            dispatch(
              setTrendChartData({
                trend_chart_data:
                  response.data.detail_value[0].esg_vs_stock.data_point,
              })
            );
            getRSSFeed()
              .then((response) => {
                dispatch(setRSSFeed({ rss_feed: response.news }));
              })
              .catch((err) => {
                // console.log(err);
              });
            setLoading(false);
          }
          dispatch(
            setCompanyDetailsLoading({ company_details_loading: false })
          );
          // console.log("The Company Details:", response.data.detail_value[0]);
        })
        .catch((err) => {
          if (err.data.non_field_errors[0] === "company does not exists") {
            setPageNotFound(true);
          }
        });
    } else {
      setLoading(false);
    }

    // window.scrollTo({ top: 0, behavior: "smooth" });
  }, [slug]);
  // document.querySelector("body").scrollTo({ top: 0, behavior: "smooth" });
  document.getElementById("root")?.scrollIntoView({ behavior: "smooth" });
  // window.scrollTo({ top: 0, behavior: "smooth" });

  const toggleControversyDescription = () => {
    setToggleControversy(!toggleControversy);
  };

  return (
    <>
      {pageNotFound ? (
        <ErrorPage />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F3F8F3 !important",
          }}
        >
          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              mt: 5,
              mb: {
                xs: 2,
                sm: 2,
                md: 0,
                lg: 0,
                xl: 0,
              },
              px: {
                xs: 1,
                sm: 2,
                md: 2,
                lg: 8,
                xl: 8,
              },
            }}
          >
            <Box
              aria-label="back"
              onClick={() => {
                navigate("/");
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#D0E3D0",
                color: "#707070",
                borderRadius: 1,
                "&:hover": {
                  backgroundColor: "#047857",
                  color: "white",
                },
                width: {
                  xs: "15%",
                  sm: "10%",
                  md: "8%",
                  lg: "5%",
                  xl: "5%",
                },
                height: {
                  xs: "2.6rem",
                  sm: "2.6rem",
                  md: "2.6rem",
                  lg: "2.6rem",
                  xl: "2.6rem",
                },
              }}
            >
              <BiChevronLeft size={30} />
            </Box>
            <Box
              sx={{
                width: {
                  xs: "70%",
                  sm: "50%",
                  md: "30%",
                  lg: "30%",
                  xl: "30%",
                },
              }}
            >
              <SearchBar />
            </Box>
          </Box>
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              display: "flex",
              flexDirection: "column",
              mt: {
                xs: 0,
                sm: 0,
                md: 2,
                lg: 2,
                xl: 2,
              },
              elevation: 5,
              justifyContent: "center",
              // boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
              borderRadius: {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
          >
            {loading ? (
              <Loading />
            ) : slug === "undefined" ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: {
                      xs: "1rem",
                      sm: "1rem",
                      md: "1.2rem",
                      lg: "1.2rem",
                      xl: "1.5rem",
                    },
                  }}
                >
                  Invalid Search. Please try again with a valid company name.
                </Typography>
                <Box
                  component="img"
                  src={UFOLogo}
                  sx={{
                    width: "10rem",
                    height: "10rem",
                    opacity: "0.8",
                  }}
                />
                <Button
                  variant="contained"
                  onClick={() => {
                    // navigate("/");
                    window.location.href = `${CLIENT_BASE_URL}`;
                  }}
                  size="medium"
                  sx={{
                    backgroundColor: "#047857",
                    color: "#fff",
                    transition: "all 0.3s linear",
                    "&:hover": {
                      backgroundColor: "#10b981",
                    },
                    border: "1px solid #10b981",

                    // p: { xs: 1, sm: 1, md: 1, lg: 2, xl: 2 },

                    textAlign: "center",
                    fontFamily: "Poppins",
                    textTransform: "none",
                    fontWeight: "100",
                  }}
                >
                  Back to ESG Dashboard
                </Button>
              </Box>
            ) : company_details_data?.length === 0 ? (
              <Box>
                <Helmet>
                  <title>Yodaplus ESG for {search_company_name}</title>
                </Helmet>
                <Typography
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: "#047857",
                    fontFamily: "Poppins",
                    textAlign: "center",
                  }}
                >
                  {search_company_name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: {
                        xs: "1rem",
                        sm: "1rem",
                        md: "1.2rem",
                        lg: "1.2rem",
                        xl: "1.5rem",
                      },
                      mt: 2,
                    }}
                  >
                    Unfortunately this company is not yet rated. Please try
                    again later.
                  </Typography>
                  <Box
                    component="img"
                    src={UFOLogo}
                    sx={{
                      width: "10rem",
                      height: "10rem",
                      opacity: "0.8",
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={() => {
                      // navigate("/");
                      window.location.href = `${CLIENT_BASE_URL}`;
                    }}
                    size="medium"
                    sx={{
                      backgroundColor: "#047857",
                      color: "#fff",
                      transition: "all 0.3s linear",
                      "&:hover": {
                        backgroundColor: "#10b981",
                      },
                      border: "1px solid #10b981",

                      // p: { xs: 1, sm: 1, md: 1, lg: 2, xl: 2 },

                      textAlign: "center",
                      fontFamily: "Poppins",
                      textTransform: "none",
                      fontWeight: "100",
                    }}
                  >
                    Back to ESG Dashboard
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  px: {
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: 0,
                    xl: 0,
                  },
                }}
              >
                <Helmet>
                  <title>
                    Yodaplus ESG for {company_details_data.company_name}
                  </title>
                </Helmet>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",

                    mx: {
                      xs: 0,
                      sm: 0,
                      md: 2,
                      lg: "4rem",
                      xl: "4rem",
                    },
                    backgroundColor: "#F5FCF5",
                    borderRadius: {
                      xs: "px",
                      sm: "px",
                      md: "px",
                      lg: "px",
                      xl: "px",
                    },
                    boxShadow: "0px 14px 16px #D4DECAB3",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.8rem",
                        fontWeight: "600",
                        color: "#000000",
                        fontFamily: "Poppins",
                        textAlign: {
                          xs: "center",
                          sm: "center",
                          md: "start",
                          lg: "start",
                          xl: "start",
                        },
                        fontStyle: "semibold",
                        mb: 2,
                        ml: {
                          xs: 0,
                          sm: 0,
                          md: "4rem",
                          lg: "4rem",
                          xl: "4rem",
                        },
                      }}
                    >
                      {company_details_data.company_name} (
                      {company_details_data.symbol})
                    </Typography>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Grid item xs={12} md={4} lg={4}>
                        <Box
                          sx={{
                            transition: "all 0.5s ease",
                          }}
                        >
                          {toggleControversy === false ? (
                            <Box>
                              <ResponsiveContainer
                                aspect={1.5}
                                width="100%"
                                height="60%"
                              >
                                <RadarChart
                                  innerRadius={20}
                                  cx="50%"
                                  cy="50%"
                                  height={220}
                                  outerRadius="95%"
                                  data={company_details_data.radar_chart_data}
                                >
                                  <PolarGrid
                                    gridType="circle"
                                    strokeWidth={1}
                                    stroke="#000000"
                                  />
                                  <PolarAngleAxis
                                    dataKey="subject"
                                    orient="outer"
                                    tick={{
                                      fontSize: "0.8rem",
                                      fill: "#000000",
                                      fontFamily: "Poppins",
                                      margin: "2px",
                                      padding: "2px",
                                    }}
                                    tickFormatter={(value) => {
                                      return value.substring(0, 1);
                                    }}
                                  />
                                  <PolarRadiusAxis
                                    angle={150}
                                    domain={[0, 33]}
                                    axisType="number"
                                    tick={{
                                      fontSize: "0.6rem",
                                      fill: "#000000",
                                      fontFamily: "Poppins",
                                    }}
                                  />

                                  <Radar
                                    name={company_details_data.symbol}
                                    dataKey="score"
                                    stroke={
                                      company_details_data.highest_controversy ===
                                      1
                                        ? "#8884d8"
                                        : company_details_data.highest_controversy ===
                                          2
                                        ? "#82ca9d"
                                        : company_details_data.highest_controversy ===
                                          3
                                        ? "#ffc658"
                                        : company_details_data.highest_controversy ===
                                          4
                                        ? "#FF3D00"
                                        : company_details_data.highest_controversy ===
                                          5
                                        ? "#FF0000"
                                        : "#000000"
                                    }
                                    fill={
                                      company_details_data.highest_controversy ===
                                      1
                                        ? "#8884d8"
                                        : company_details_data.highest_controversy ===
                                          2
                                        ? "#82ca9d"
                                        : company_details_data.highest_controversy ===
                                          3
                                        ? "#ffc658"
                                        : company_details_data.highest_controversy ===
                                          4
                                        ? "#FF3D00"
                                        : company_details_data.highest_controversy ===
                                          5
                                        ? "#FF0000"
                                        : "#000000"
                                    }
                                    fillOpacity={0.6}
                                    dot={false}
                                    strokeWidth={2}
                                    // strokeLinejoin="round"
                                    strokeLinecap="round"
                                    margin={{
                                      bottom: 10,
                                    }}
                                    // label={{
                                    //   position: "bottom",
                                    //   fontSize: "0.7rem",
                                    //   fontFamily: "Ubuntu, sans-serif",
                                    //   fontWeight: "bold",
                                    //   color: "#000000",
                                    // }}
                                    legendType="circle"
                                  />
                                  {/* <Tooltip offset={20} /> */}
                                  <Tooltip
                                    content={
                                      <ControversyTooltip
                                        controversy={
                                          company_details_data.highest_controversy
                                        }
                                      />
                                    }
                                  />
                                  <Legend
                                    wrapperStyle={{
                                      fontSize: "0.8rem",
                                      fontFamily: "Poppins",
                                      position: "relative",
                                      marginTop: "0.1rem",
                                    }}
                                  />
                                </RadarChart>
                              </ResponsiveContainer>
                              {/* <ESGRadarChart
                                radar_chart_data={
                                  company_details_data.radar_chart_data
                                }
                                highest_controversy={
                                  company_details_data.highest_controversy
                                }
                                symbol={company_details_data.symbol}
                              /> */}

                              {company_details_data.radar_chart_data[0]
                                .score === 0 &&
                              company_details_data.radar_chart_data[1].score ===
                                0 &&
                              company_details_data.radar_chart_data[2].score ===
                                0 ? (
                                <Box
                                  sx={{
                                    mt: {
                                      xs: "1.5rem",
                                      sm: "1rem",
                                      md: "1rem",
                                      lg: "1rem",
                                      xl: "1rem",
                                    },
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontFamily: "Poppins",
                                      fontSize: {
                                        xs: "0.8rem",
                                        sm: "0.8rem",
                                        md: "0.8rem",
                                        lg: "0.8rem",
                                        xl: "0.8rem",
                                      },

                                      color: "#95999E",
                                      textAlign: "center",
                                    }}
                                  >
                                    Score Bifurcation Not Available
                                  </Typography>
                                </Box>
                              ) : null}
                            </Box>
                          ) : (
                            <ControversyFlip
                              controversyLevel={
                                company_details_data.highest_controversy
                              }
                            />
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: {
                              xs: "flex",
                              sm: "flex",
                              md: "none",
                              lg: "none",
                              xl: "none",
                            },
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 3,
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => {
                              toggleControversyDescription();
                            }}
                            sx={{
                              backgroundColor: "#047857",
                              color: "#fff",
                              transition: "all 0.3s linear",
                              "&:hover": {
                                backgroundColor: "#06AF7F",
                              },
                              width: "70%",
                            }}
                          >
                            {toggleControversy === false
                              ? "Controversy Level"
                              : "View Chart"}
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={8} lg={8}>
                        <ESGDetails
                          total_esg={company_details_data.total_esg}
                          environment_score={
                            company_details_data.environment_score
                          }
                          social_score={company_details_data.social_score}
                          governance_score={
                            company_details_data.governance_score
                          }
                          rating={company_details_data.ratings}
                          controversy={company_details_data.highest_controversy}
                          updatedYear={company_details_data.registered_year}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mx: {
                      xs: 0,
                      sm: 0,
                      md: 2,
                      lg: "4rem",
                      xl: "4rem",
                    },
                    backgroundColor: "#F5FCF5",
                    borderRadius: {
                      xs: "px",
                      sm: "px",
                      md: "px",
                      lg: "px",
                      xl: "px",
                    },
                    boxShadow: "0px 14px 16px #D4DECAB3",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <PeerRiskScore
                      industry={company_details_data.industry}
                      peer_ratings={company_details_data.peer_ratings}
                    />
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mx: {
                      xs: 0,
                      sm: 0,
                      md: 2,
                      lg: "4rem",
                      xl: "4rem",
                    },
                    backgroundColor: "#F5FCF5",
                    borderRadius: {
                      xs: "px",
                      sm: "px",
                      md: "px",
                      lg: "px",
                      xl: "px",
                    },
                    boxShadow: "0px 14px 16px #D4DECAB3",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      px: {
                        xs: 2,
                        sm: 2,
                        md: 8,
                        lg: 8,
                        xl: 8,
                      },
                    }}
                  >
                    <CompanyDescriptionCard
                      companyDescription={
                        company_details_data.company_description
                      }
                    />
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mx: {
                      xs: 0,
                      sm: 0,
                      md: 2,
                      lg: "4rem",
                      xl: "4rem",
                    },
                    backgroundColor: "#F5FCF5",
                    borderRadius: {
                      xs: "px",
                      sm: "px",
                      md: "px",
                      lg: "px",
                      xl: "px",
                    },
                    boxShadow: "0px 14px 16px #D4DECAB3",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      px: {
                        xs: 2,
                        sm: 2,
                        md: 8,
                        lg: 8,
                        xl: 8,
                      },
                    }}
                  >
                    <Box>
                      {trend_chart_data?.length > 0 ? (
                        <ESGSharePriceChart
                          chart_data={trend_chart_data}
                          currency={company_details_data.currency}
                        />
                      ) : (
                        <Loading />
                      )}
                    </Box>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mx: {
                      xs: 0,
                      sm: 0,
                      md: 2,
                      lg: "4rem",
                      xl: "4rem",
                    },
                    backgroundColor: "#F5FCF5",
                    borderRadius: {
                      xs: "px",
                      sm: "px",
                      md: "px",
                      lg: "px",
                      xl: "px",
                    },
                    boxShadow: "0px 14px 16px #D4DECAB3",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      px: {
                        xs: 2,
                        sm: 2,
                        md: 8,
                        lg: 8,
                        xl: 8,
                      },
                    }}
                  >
                    <ProductAreas
                      palm_oil={company_details_data.palm_oil}
                      controversial_weapons={
                        company_details_data.controversial_weapons
                      }
                      gambling={company_details_data.gambling}
                      nuclear={company_details_data.nuclear}
                      fur_leather={company_details_data.fur_leather}
                      alcoholic={company_details_data.alcoholic}
                      gmo={company_details_data.gmo}
                      catholic={company_details_data.catholic}
                      animal_testing={company_details_data.animal_testing}
                      tobacco={company_details_data.tobacco}
                      coal={company_details_data.coal}
                      pesticides={company_details_data.pesticides}
                      adult={company_details_data.adult}
                      small_arms={company_details_data.small_arms}
                      miltiary_contract={company_details_data.miltiary_contract}
                    />
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    mx: {
                      xs: 0,
                      sm: 0,
                      md: 2,
                      lg: "4rem",
                      xl: "4rem",
                    },
                    backgroundColor: "#F5FCF5",
                    borderRadius: {
                      xs: "px",
                      sm: "px",
                      md: "px",
                      lg: "px",
                      xl: "px",
                    },
                    boxShadow: "0px 14px 16px #D4DECAB3",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      px: {
                        xs: 2,
                        sm: 2,
                        md: 8,
                        lg: 8,
                        xl: 8,
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "1.2rem",
                          sm: "1.5rem",
                          md: "1.5rem",
                          lg: "1.5rem",
                          xl: "1.5rem",
                        },
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        color: "#047857",
                        textTransform: "uppercase",
                        mb: 2,
                      }}
                    >
                      ESG News
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <NewsRSSFeed rss_feed={rss_feed} />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ESGCompanyDetail;
