import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Tab,
  Box,
  Grid,
  Button,
  Pagination,
  PaginationItem,
  Divider,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useNavigate, Link } from "react-router-dom";
import Loading from "./Loading";
import { useSelector, useDispatch } from "react-redux";
import {
  Legend,
  ResponsiveContainer,
  Tooltip,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import { setFocusedCountry, setFocusedIndice } from "../store/reducers/indices";
import {
  setIndustryFilterValue,
  setRatingFilterValue,
  setESGCFilterValue,
  setChecked,
  setCheckedRating,
} from "../store/reducers/filterList";
import {
  setCompanyDetailsData,
  setCompanyDetailsLoading,
  setTrendChartData,
} from "../store/reducers/companyDetails";
import {
  setStockListdata,
  setStockListLoading,
  setPageNumber,
} from "../store/reducers/stockList";
import { BiChevronsRight, BiChevronsLeft } from "react-icons/bi";
import UseWindowDimensions from "../service/UseWindowDimensions";
import { CLIENT_BASE_URL, SERVER_TYPE } from "../esg_project_config";
import SearchBar from "./SearchBar";
import ESGRadarChart from "./ESGRadarChart";

const MainDashboardContentCard = () => {
  const navigate = useNavigate();
  const page_number = useSelector((state) => state.stockList.page_number);
  const country_list = useSelector((state) => state.indiceList.country_list);
  const max_page = useSelector((state) => state.stockList.max_pages);
  const focused_country = useSelector(
    (state) => state.indiceList.focused_country
  );
  // console.log(focused_country, "The Focused Country");
  // console.log(country_list, "The Country List");
  const stock_list_data = useSelector(
    (state) => state.stockList.stock_list_data
  );
  const stock_list_loading = useSelector(
    (state) => state.stockList.stock_list_loading
  );
  const focused_indice = useSelector(
    (state) => state.indiceList.focused_indice
  );
  const indice_list = useSelector((state) => state.indiceList.indice_list);
  // console.log(indice_list, "888888");
  const dispatch = useDispatch();

  const handleTab = (event, newValue) => {
    // console.log(newValue, "opopopo");
    dispatch(setRatingFilterValue({ selected_rating_filter: null }));
    dispatch(setIndustryFilterValue({ selected_industry_filter: null }));
    dispatch(setESGCFilterValue({ selected_esgc_filter: null }));
    dispatch(setStockListdata({ stock_list_data: [] }));
    dispatch(setChecked({ checked: [0] }));
    dispatch(setCheckedRating({ checkedRating: [0] }));
    dispatch(setStockListLoading({ stock_list_loading: true }));
    // dispatch(setFocusedIndice({ focused_indice: newValue }));
    let index_to_be_focused;
    if (newValue !== "all") {
      country_list.filter((country) => {
        if (newValue === country.id) {
          // console.log(country.indices[0].id);
          index_to_be_focused = country.indices[0].id;
        }
      });
    } else {
      index_to_be_focused = newValue;
    }
    // console.log(index_to_be_focused, "ooioioioioi");
    dispatch(setFocusedCountry({ focused_country: newValue }));
    dispatch(setFocusedIndice({ focused_indice: index_to_be_focused }));
    dispatch(setPageNumber({ page_number: 1 }));
  };

  const handlePagination = (event, value) => {
    dispatch(setPageNumber({ page_number: value }));
    dispatch(setStockListdata({ stock_list_data: [] }));
    dispatch(setStockListLoading({ stock_list_loading: true }));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleIndiceButton = (value) => {
    dispatch(setRatingFilterValue({ selected_rating_filter: null }));
    dispatch(setIndustryFilterValue({ selected_industry_filter: null }));
    dispatch(setESGCFilterValue({ selected_esgc_filter: null }));
    dispatch(setFocusedIndice({ focused_indice: value }));
    dispatch(setPageNumber({ page_number: 1 }));
    dispatch(setStockListdata({ stock_list_data: [] }));
    dispatch(setChecked({ checked: [0] }));
    dispatch(setCheckedRating({ checkedRating: [0] }));
    dispatch(setStockListLoading({ stock_list_loading: true }));
  };

  const handleDetailPage = (slug, company_name) => {
    dispatch(setCompanyDetailsData({ company_details_data: [] }));
    dispatch(setTrendChartData({ trend_chart_data: [] }));
    dispatch(setCompanyDetailsLoading({ company_details_loading: true }));
    // dispatch(setCompanyDetailSlug({ company_slug: slug }));
    // let new_company_name = company_name.replace(/[., ]+/g, "").toLowerCase();
    // console.log(new_company_name, "new company name");
    // navigate(`/esg-risk-ratings/${slug}`);
    // window.location.href = `${CLIENT_BASE_URL}esg-risk-ratings/${slug}`;
    // window.open(`${BASE_URL}/esg-risk-ratings/${slug}`, "_self");
  };
  const { width } = UseWindowDimensions();
  // console.log(height, width, "The Height and Width");
  return (
    <Card
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        mt: 5,
        elevation: 5,
        justifyContent: "center",
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2)",
        borderRadius: "10px",
        backgroundColor: "#F3F8F3",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {focused_indice === null ? (
            <Loading />
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TabContext
                value={focused_country}
                sx={{
                  "& .MuiTab-root": {
                    color: "#000",
                  },
                  "& .Mui-selected": {
                    color: "#06AF7F",
                    backgroundColor: "#06AF7F",
                  },
                  height: "10px",
                  margin: 0,
                  padding: 0,
                }}
              >
                <TabList
                  variant="scrollable"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#F3F8F3",
                      opacity: 0,
                    },
                  }}
                  sx={{
                    "& .MuiTab-root": {
                      color: "#000",
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#047857 !important",
                      borderRadius: "6px !important",
                      color: "#ffffff !important",
                    },
                  }}
                  allowScrollButtonsMobile
                  scrollButtons="auto"
                  onChange={handleTab}
                >
                  {country_list.map((country, index) => {
                    if (country.id !== "all") {
                      // console.log(indice.id, "uiiuiu");
                      let labelData = `${country.country}`;
                      return (
                        <Tab
                          key={index}
                          label={labelData}
                          value={country.id}
                          wrapped
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "13px",
                            minHeight: "3rem",
                          }}
                          icon={
                            <img
                              alt=" "
                              src={country.country_flag}
                              style={{
                                width: "20px",
                              }}
                            />
                          }
                          iconPosition="end"
                        />
                      );
                    } else {
                      return (
                        <Tab
                          key={index}
                          label="All"
                          value="all"
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "13px",
                          }}
                        />
                      );
                    }
                  })}
                  <Tab
                    label="All"
                    value="all"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                    }}
                  />
                </TabList>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent:
                      indice_list.length > 0 ? "space-between" : "flex-end",
                    alignItems: " center",
                    mt: indice_list.length > 0 ? 0 : 2,
                  }}
                >
                  {indice_list.length > 0 ? (
                    <Box>
                      {indice_list.map((indice, index) => {
                        return (
                          <Button
                            key={index}
                            onClick={() => handleIndiceButton(indice.id)}
                            variant="outlined"
                            sx={{
                              mx: 1,
                              my: 2,
                              color:
                                focused_indice === indice.id
                                  ? "#ffffff"
                                  : "#06AF7F",
                              borderColor:
                                focused_indice === indice.id ? null : "#06AF7F",
                              backgroundColor:
                                focused_indice === indice.id ? "#047857" : null,
                              "&:hover": {
                                backgroundColor: "#047857",
                                color: "#ffffff",
                                border: null,
                              },
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            {indice.name}
                          </Button>
                        );
                      })}
                    </Box>
                  ) : null}
                  <Box
                    sx={{
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        lg: "flex",
                        xl: "flex",
                      },
                      width: "40%",
                    }}
                  >
                    <SearchBar />
                  </Box>
                </Box>

                <TabPanel
                  value={focused_country}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {stock_list_loading ? (
                      <Loading />
                    ) : (
                      <>
                        {stock_list_data?.length === 0 ? (
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                            }}
                          >
                            No Data Available for this Indice
                          </Typography>
                        ) : (
                          stock_list_data.map((stock, index) => {
                            return (
                              // <Link
                              //   to={`${CLIENT_BASE_URL}esg-risk-ratings/${stock.slug}`}
                              //   onClick={() => {
                              //     console.log(stock.slug, "stock slug");
                              //     console.log(stock.name, "stock name");
                              //     handleDetailPage(stock.slug, stock.name);
                              //   }}
                              // >
                              <Card
                                key={index}
                                component={
                                  SERVER_TYPE !== "local" ? Link : null
                                }
                                to={
                                  SERVER_TYPE !== "local"
                                    ? `${CLIENT_BASE_URL}esg-risk-ratings/${stock.slug}`
                                    : null
                                }
                                sx={{
                                  my: 2,
                                  boxShadow: "0px 14px 16px #D4DECAB3",

                                  borderRadius: "10px",
                                  cursor: "pointer",
                                  textDecoration: "none",
                                  "&:hover": {
                                    underline: "none",
                                    textDecoration: "none",
                                  },
                                  backgroundColor: "#F5FCF5",
                                }}
                                onClick={() => {
                                  if (SERVER_TYPE === "local") {
                                    handleDetailPage(stock.slug, stock.name);
                                    navigate(`/esg-risk-ratings/${stock.slug}`);
                                  }
                                }}
                              >
                                <CardContent>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        // fontWeight: "bold",
                                        fontSize: "1.4rem",
                                        color: "#000000",
                                        fontFamily: "Poppins",
                                        alignText: "start",
                                        underline: "none",
                                        textDecoration: "none",
                                        "&:hover": {
                                          underline: "none",
                                          textDecoration: "none",
                                        },
                                        px: 2,
                                        pb: 1,
                                      }}
                                    >
                                      {stock.name} ({stock.symbol})
                                    </Typography>
                                    <Divider
                                      sx={{
                                        borderBottomWidth: 1,
                                        borderBottomColor: "#D4DECAB3",
                                        width: "100%",
                                        display: "flex",
                                      }}
                                    />
                                  </Box>
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={3}
                                      lg={3}
                                      xl={3}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <ESGRadarChart
                                        radar_chart_data={
                                          stock.radar_chart_data
                                        }
                                        highest_controversy={
                                          stock.highest_controversy
                                        }
                                        symbol={stock.symbol}
                                      />
                                      {stock.radar_chart_data[0].score === 0 &&
                                      stock.radar_chart_data[1].score === 0 &&
                                      stock.radar_chart_data[2].score === 0 ? (
                                        <Box
                                          sx={{
                                            mt: {
                                              xs: "3rem",
                                              sm: 0,
                                              md: 0,
                                              lg: 0,
                                              xl: 0,
                                            },
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontFamily: "Poppins",
                                              fontSize: {
                                                xs: "0.8rem",
                                                sm: "0.8rem",
                                                md: "0.8rem",
                                                lg: "0.8rem",
                                                xl: "1rem",
                                              },
                                              textAlign: "center",
                                              underline: "none",
                                              textDecoration: "none",
                                              "&:hover": {
                                                underline: "none",
                                                textDecoration: "none",
                                              },
                                            }}
                                          >
                                            Score Bifurcation Not Available
                                          </Typography>
                                        </Box>
                                      ) : null}
                                    </Grid>
                                    <Grid
                                      item
                                      xs="none"
                                      sm="none"
                                      md={6}
                                      lg={6}
                                      xl={6}
                                      sx={{
                                        display: {
                                          xs: "none",
                                          sm: "none",
                                          md: "flex",
                                          lg: "flex",
                                          xl: "flex",
                                        },
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          width: "100%",
                                          mt: 2,
                                        }}
                                      >
                                        {Object.keys(stock.esg_vs_stock)
                                          .length > 0 ? (
                                          <ResponsiveContainer
                                            className="watermark"
                                            aspect={1.7}
                                            width="100%"
                                            height="100%"
                                          >
                                            <ComposedChart
                                              data={
                                                stock.esg_vs_stock.data_point
                                              }
                                            >
                                              <XAxis
                                                dataKey="date"
                                                tickFormatter={(value) => {
                                                  return value.substring(0, 7);
                                                }}
                                                tickCount={
                                                  Object.keys(
                                                    stock.esg_vs_stock
                                                  ).length
                                                }
                                                // label={{
                                                //   value: "Date",
                                                //   style: { textAnchor: "middle" },

                                                //   position: "bottom",
                                                //   offset: -2,
                                                // }}
                                              />
                                              <YAxis
                                                yAxisId="left-axis"
                                                reversed={true}
                                                label={{
                                                  value: "ESG Score",
                                                  style: {
                                                    textAnchor: "middle",
                                                  },
                                                  angle: -90,
                                                  position: "left",
                                                  offset: -2,
                                                }}
                                              />
                                              <YAxis
                                                yAxisId="right-axis"
                                                orientation="right"
                                                label={{
                                                  value: `Share Price (${stock.currency})`,
                                                  style: {
                                                    textAnchor: "middle",
                                                  },
                                                  angle: 90,
                                                  position: "right",
                                                  offset: -2,
                                                }}
                                              />
                                              <Tooltip
                                                formatter={(value) => {
                                                  return value.toFixed(2);
                                                }}
                                                labelFormatter={(value) => {
                                                  return value.substring(0, 7);
                                                }}
                                              />
                                              <Legend />
                                              <CartesianGrid stroke="#f5f5f5" />
                                              <Line
                                                yAxisId="left-axis"
                                                type="number"
                                                name="Total ESG"
                                                dataKey="total_esg"
                                                stroke="#ff7300"
                                                reversed={true}
                                              />
                                              <Line
                                                yAxisId="right-axis"
                                                type="monotone"
                                                name="Stock Closing Price"
                                                dataKey="stock_closing_price"
                                                stroke="#413ea0"
                                              />
                                            </ComposedChart>
                                          </ResponsiveContainer>
                                        ) : null}
                                      </Box>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={3}
                                      lg={3}
                                      xl={3}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mt:
                                          stock.radar_chart_data[0].score ===
                                            0 &&
                                          stock.radar_chart_data[1].score ===
                                            0 &&
                                          stock.radar_chart_data[2].score === 0
                                            ? {
                                                xs: 0,
                                                sm: 0,
                                                md: 0,
                                                lg: 0,
                                                xl: 0,
                                              }
                                            : {
                                                xs: 4,
                                                sm: 4,
                                                md: 0,
                                                lg: 0,
                                                xl: 0,
                                              },
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          backgroundColor: "#e6f1e6",
                                          width: "80%",
                                          height: {
                                            xs: "100%",
                                            sm: "100%",
                                            md: "100%",
                                            lg: "90%",
                                            xl: "90%",
                                          },
                                          borderRadius: "2px",
                                          my: {
                                            xs: "0.8rem",
                                            sm: "0.8rem",
                                            md: "0.8rem",
                                            lg: "0.8rem",
                                            xl: "0rem",
                                          },
                                          py: {
                                            xs: "0.8rem",
                                            sm: "0.8rem",
                                            md: "0.8rem",
                                            lg: "2.0rem",
                                            xl: "0rem",
                                          },
                                          px: {
                                            xs: "0.8rem",
                                            sm: "0.8rem",
                                            md: "0.8rem",
                                            lg: "0.8rem",
                                            xl: "0rem",
                                          },
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: {
                                              xs: "0.8rem",
                                              sm: "0.8rem",
                                              md: "1.5rem",
                                              lg: "1.8rem",
                                              xl: "1.8rem",
                                            },
                                            color: "#1D2024",
                                            fontFamily: "Poppins",
                                            fontStyle: "light",
                                            underline: "none",
                                            fontWeight: "100",

                                            textDecoration: "none",
                                            "&:hover": {
                                              underline: "none",
                                              textDecoration: "none",
                                            },
                                          }}
                                        >
                                          ESG Score
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontWeight: "bold",
                                            fontSize: {
                                              xs: "1.2rem",
                                              sm: "1.2rem",
                                              md: "2.3rem",
                                              lg: "2.5rem",
                                              xl: "2.5rem",
                                            },

                                            fontFamily: "Poppins",
                                            underline: "none",

                                            textDecoration: "none",
                                            "&:hover": {
                                              underline: "none",
                                              textDecoration: "none",
                                            },
                                            color:
                                              stock.rating === "Excellent"
                                                ? "#8884d8"
                                                : stock.rating === "Very Good"
                                                ? "#0A8A3A"
                                                : stock.rating === "Good"
                                                ? "#ffc658"
                                                : stock.rating ===
                                                  "Satisfactory"
                                                ? "#FF3D00"
                                                : stock.rating === "Poor"
                                                ? "#FF0000"
                                                : "#000000",
                                          }}
                                        >
                                          {stock.total_esg.toFixed(2)}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: {
                                              xs: "0.8rem",
                                              sm: "0.8rem",
                                              md: "1rem",
                                              lg: "1rem",
                                              xl: "1.2rem",
                                            },
                                            color: "#000000",
                                            fontFamily: "Poppins",
                                            underline: "none",

                                            textDecoration: "none",
                                            "&:hover": {
                                              underline: "none",
                                              textDecoration: "none",
                                            },
                                          }}
                                        >
                                          {stock.rating}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: {
                                              xs: "0.8rem",
                                              sm: "0.8rem",
                                              md: "0.5rem",
                                              lg: "0.6rem",
                                              xl: "0.8rem",
                                            },
                                            color: "#95999E",
                                            fontFamily: "Poppins",
                                            underline: "none",
                                            textDecoration: "none",
                                            "&:hover": {
                                              underline: "none",
                                              textDecoration: "none",
                                            },
                                          }}
                                        >
                                          Last ESG Update:{" "}
                                          {stock.registered_year.substring(
                                            0,
                                            7
                                          )}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontWeight: "bold",
                                            fontSize: "1rem",
                                            color: "#047857",
                                            fontFamily: "Poppins",
                                            alignText: "center",
                                            underline: "none",
                                            textTransform: "uppercase",
                                            px: 2,
                                            pt: {
                                              xs: 2,
                                              sm: 2,
                                              md: 2,
                                              lg: 5,
                                              xl: 5,
                                            },
                                          }}
                                        >
                                          Know More
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                              // </Link>
                            );
                          })
                        )}
                      </>
                    )}
                  </Box>
                </TabPanel>
              </TabContext>
              {stock_list_data?.length === 0 ? null : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Pagination
                    count={max_page}
                    variant="outlined"
                    shape="rounded"
                    size="large"
                    siblingCount={width < 540 ? 0 : 1}
                    boundaryCount={width < 540 ? 0 : 1}
                    width="100%"
                    sx={{
                      mt: 2,
                      mb: 2,

                      " .MuiPaginationItem-root": {
                        color: "#047857",
                        borderColor: "#047857",
                        width: "2.5rem",

                        "&.Mui-selected": {
                          color: "#ffffff",
                          backgroundColor: "#047857",
                        },
                        "&:hover": {
                          color: "#ffffff",
                          backgroundColor: "#047857",
                        },
                      },
                    }}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: BiChevronsLeft,
                          next: BiChevronsRight,
                        }}
                        {...item}
                      />
                    )}
                    page={page_number}
                    onChange={handlePagination}
                  />

                  {/* <Button
                  onClick={handleLeftScroll}
                  onDoubleClick={handleFastLeftScroll}
                  sx={{
                    fontSize: "2.0rem",
                    color: "#047857",
                  }}
                >
                  <BiChevronsLeft />
                </Button>
                <Box
                  ref={page_ref}
                  sx={{
                    width: {
                      xs: "90%",
                      sm: "80%",
                      md: "60%",
                      lg: "30%",
                      xl: "30%",
                    },
                    display: "flex",
                    overflow: "hidden",
                    scrollBehavior: "smooth",
                    justifyContent: max_page <= 5 ? "center" : "none",
                    alignItems: max_page <= 5 ? "center" : "none",
                  }}
                >
                  {[...Array(max_page)].map((e, i) => {
                    return (
                      <Button
                        key={i}
                        variant="outlined"
                        sx={{
                          mx: 1,
                          my: 2,
                          color: i + 1 === page_number ? "#ffffff" : "#06AF7F",
                          borderColor: i + 1 === page_number ? null : "#06AF7F",
                          backgroundColor:
                            i + 1 === page_number ? "#047857" : null,
                          "&:hover": {
                            backgroundColor: "#047857",
                            color: "#ffffff",
                            border: null,
                          },
                          fontFamily: "Poppins",
                        }}
                        onClick={() => {
                          if (page_number !== i + 1) {
                            handlePagination(i + 1);
                          }

                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        {i + 1}
                      </Button>
                    );
                  })}
                </Box>
                <Button
                  onClick={handleRightScroll}
                  onDoubleClick={handleFastRightScroll}
                  sx={{
                    fontSize: "2.0rem",
                    color: "#047857",
                  }}
                >
                  <BiChevronsRight />
                </Button> */}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default MainDashboardContentCard;
