import React from "react";
import { Box, Typography } from "@mui/material";

const ControveryLegend = ({ controversyScore }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        mt: {
          xs: "1rem",
          sm: "1rem",
          md: "1rem",
          lg: "1rem",
          xl: "1rem",
        },
        mb: {
          xs: "1rem",
          sm: 0,
          md: 0,
          lg: 0,
          xl: 0,
        },
      }}
    >
      <Typography
        sx={{
          fontSize: {
            xs: "1rem",
            sm: "1rem",
            md: "1rem",
            lg: "1rem",
            xl: "1.1rem",
          },
          color: "#000000",
          fontFamily: "Poppins",
          textAlign: "center",
          fontWeight: "bold",
          mb: {
            xs: 1,
            sm: 1,
            md: 0.5,
            lg: 0.5,
            xl: 0.5,
          },
        }}
      >
        Controversy Score Legend
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",

          alignItems: "center",
          width: {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "100%",
            xl: "100%",
          },
          height: {
            xs: "1.3rem",
            sm: "1.3rem",
            md: "1.5rem",
            lg: "1.6rem",
            xl: "1.7rem",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "16.66%",
            backgroundColor: "#000000",
            height: controversyScore === 0 ? "27px" : "19px",
            justifyContent: "center",
            alignItems: "center",
            border: controversyScore === 0 ? "2px solid #FFFFFF" : "none",
            boxShadow:
              controversyScore === 0 ? "0px 3px 16px #6767675C" : "none",
          }}
        >
          <Typography
            sx={{
              color: "#FFFFFF",
              fontFamily: "Poppins",
              fontSize: "0.8rem",
              fontWeight: controversyScore === 0 ? "bold" : "100",
            }}
          >
            0
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "16.66%",
            height: controversyScore === 1 ? "27px" : "19px",

            // backgroundColor: "#8884d8",
            backgroundColor: "#5F58EE",
            justifyContent: "center",
            alignItems: "center",
            // border: controversyScore === 1 ? "3px solid #FF774D" : "none",
            border: controversyScore === 1 ? "2px solid #FFFFFF" : "none",
            boxShadow:
              controversyScore === 1 ? "0px 3px 16px #6767675C" : "none",
          }}
        >
          <Typography
            sx={{
              color: "#FFFFFF",
              fontFamily: "Poppins",
              fontSize: "0.8rem",
              fontWeight: controversyScore === 1 ? "bold" : "100",
            }}
          >
            1
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "16.66%",
            height: controversyScore === 2 ? "27px" : "19px",
            // backgroundColor: "#82ca9d",
            backgroundColor: "#1EB155",
            justifyContent: "center",
            alignItems: "center",
            // border: controversyScore === 2 ? "3px solid #ffc658" : "none",
            border: controversyScore === 2 ? "2px solid #FFFFFF" : "none",
            boxShadow:
              controversyScore === 2 ? "0px 3px 16px #6767675C" : "none",
          }}
        >
          <Typography
            sx={{
              color: "#FFFFFF",
              fontFamily: "Poppins",
              fontSize: "0.8rem",
              fontWeight: controversyScore === 2 ? "bold" : "100",
            }}
          >
            2
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "16.66%",
            height: controversyScore === 3 ? "27px" : "19px",
            // backgroundColor: "#ffc658",
            backgroundColor: "#EBAC33",
            justifyContent: "center",
            alignItems: "center",
            // border: controversyScore === 3 ? "3px solid #82ca9d" : "none",
            border: controversyScore === 3 ? "2px solid #FFFFFF" : "none",
            boxShadow:
              controversyScore === 3 ? "0px 3px 16px #6767675C" : "none",
          }}
        >
          <Typography
            sx={{
              color: "#FFFFFF",
              fontFamily: "Poppins",
              fontSize: "0.8rem",
              fontWeight: controversyScore === 3 ? "bold" : "100",
            }}
          >
            3
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "16.66%",
            height: controversyScore === 4 ? "27px" : "19px",
            // backgroundColor: "#FF774D",
            backgroundColor: "#F86336",
            justifyContent: "center",
            alignItems: "center",
            // border: controversyScore === 4 ? "3px solid #8884d8" : "none",
            border: controversyScore === 4 ? "2px solid #FFFFFF" : "none",
            boxShadow:
              controversyScore === 4 ? "0px 3px 16px #6767675C" : "none",
          }}
        >
          <Typography
            sx={{
              color: "#FFFFFF",
              fontFamily: "Poppins",
              fontSize: "0.8rem",
              fontWeight: controversyScore === 4 ? "bold" : "100",
            }}
          >
            4
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "16.66%",
            height: controversyScore === 5 ? "27px" : "19px",
            backgroundColor: "#FF0000",
            justifyContent: "center",
            alignItems: "center",
            // border: controversyScore === 5 ? "3px solid #000000" : "none",
            border: controversyScore === 5 ? "2px solid #FFFFFF" : "none",
            boxShadow:
              controversyScore === 5 ? "0px 3px 16px #6767675C" : "none",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "0.8rem",
              color: "#FFFFFF",
              fontWeight: controversyScore === 5 ? "bold" : "100",
            }}
          >
            5
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",

          alignItems: "center",
          width: {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "100%",
            xl: "100%",
          },
          height: {
            xs: "1.3rem",
            sm: "1.3rem",
            md: "1.5rem",
            lg: "1.6rem",
            xl: "1.7rem",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "50%",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontFamily: "Poppins",
              fontSize: "0.6rem",
            }}
          >
            None
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "50%",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontFamily: "Poppins",
              fontSize: "0.6rem",
            }}
          >
            Severe
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ControveryLegend;
