import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Card,
  Button,
  CardContent,
} from "@mui/material";
import DesktopFilterCard from "../components/DesktopFilterCard";
// import MobileFilterCard from "../components/MobileFilterCard";
import MainDashboardContentCard from "../components/MainDashboardContentCard";
import MobileDashboardContentCard from "../components/MobileDashboardContentCard";
// import getESGData from "../service/API/DashboardAPI";
import { getIndices, getESGData } from "../service/API/DashboardAPI";
import { getFilters } from "../service/API/FilterAPI";
import Loading from "../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import {
  setFocusedCountry,
  setFocusedIndice,
  setIndicesList,
  setFocusedCountryName,
  setFocusedCountryFlag,
} from "../store/reducers/indices";
import {
  setMaxPageValue,
  setStockListdata,
  setStockListLoading,
  setResetFilters,
} from "../store/reducers/stockList";
import {
  setIndustryFilterList,
  setRatingFilterList,
} from "../store/reducers/filterList";
// import MobileBanner from "../assets/Banner-768.svg";
// import DesktopBanner from "../assets/Banner-1920.svg";
import SearchBar from "../components/SearchBar";
import ESGImage from "../assets/ESG-Image.svg";
// import { setCompanyDetailSlug } from "../store/reducers/companyDetails";

// const filterIndice = "c2920bbb-1207-4a72-af75-0c972f680d7d";

const ESGDashboard = () => {
  const dispatch = useDispatch();
  //Load ESG Data

  const page_number = useSelector((state) => state.stockList.page_number);
  const selected_industry_filter = useSelector(
    (state) => state.filterList.selected_industry_filter
  );
  const selected_rating_filter = useSelector(
    (state) => state.filterList.selected_rating_filter
  );
  const selected_esgc_filter = useSelector(
    (state) => state.filterList.selected_esgc_filter
  );
  const focused_indice = useSelector(
    (state) => state.indiceList.focused_indice
  );
  const reset_filters = useSelector((state) => state.stockList.reset_filters);

  // Get the indice data from the API
  useEffect(() => {
    if (focused_indice == null) {
      getIndices()
        .then((data) => {
          const indice = data.data.country_list;
          // console.log(indice.indices, "iii", indice);
          dispatch(setIndicesList({ country_list: indice }));
          dispatch(setFocusedCountry({ focused_country: indice[0].id }));
          dispatch(
            setFocusedIndice({ focused_indice: indice[0].indices[0].id })
          );
          dispatch(
            setFocusedCountryName({
              focused_country_name: indice[0].country,
            })
          );
          dispatch(
            setFocusedCountryFlag({
              focused_country_flag: indice[0].country_flag,
            })
          );
          // dispatch(setCompanyDetailSlug({ company_slug: null }));
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (focused_indice != null) {
      let params = {
        pagination: page_number,
        filterIndice: focused_indice,
        industryFilter: selected_industry_filter,
        ratingFilter: selected_rating_filter,
        esgc_filter: selected_esgc_filter,
      };
      // ESG Data
      getESGData(params)
        .then((response) => {
          // console.log(response.data.stock_list.data, "response");
          dispatch(
            setStockListdata({ stock_list_data: response.data.stock_list.data })
          );
          dispatch(
            setMaxPageValue({ max_pages: response.data.stock_list.max_page })
          );

          // dispatch(setCompanyDetailSlug({ company_slug: null }));
        })
        .then(() => {
          dispatch(setStockListLoading({ stock_list_loading: false }));
          dispatch(setResetFilters({ reset_filters: false }));
        });

      // Filter Data
      getFilters(focused_indice)
        .then((data) => {
          dispatch(
            setIndustryFilterList({
              industry_filter_list: data.data.industry_filter,
            })
          );
          dispatch(
            setRatingFilterList({
              rating_filter_list: data.data.rating_filter,
            })
          );
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }, [
    focused_indice,
    page_number,
    selected_rating_filter,
    selected_industry_filter,
    reset_filters,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Helmet>
        <title>YODAPLUS ESG </title>
      </Helmet>
      <Card
        sx={{
          display: {
            xs: "flex",
            sm: "flex",
            md: "none",
            lg: "none",
            xl: "none",
          },
          borderRadius: "0px",
          background:
            "transparent radial-gradient(circle closest-corner at 55% 30%, #00C28A 0%, #0B2E24 100%) 0% 0% no-repeat padding-box",
          width: "100%",
          height: {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "20rem",
            xl: "25rem",
          },
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: {
                  xs: "12rem",
                  sm: "10rem",
                  md: "15rem",
                  lg: "20rem",
                  xl: "25rem",
                },
              }}
            >
              <Box component="img" src={ESGImage} />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "100%",
                      lg: "100%",
                      xl: "100%",
                    },
                    textAlign: "center",
                    fontSize: {
                      xs: "1.8rem",
                      sm: "2rem",
                      md: "1.8rem",
                      lg: "2.5rem",
                      xl: "3rem",
                    },
                    fontWeight: "400",
                    color: "#ffffff",
                    fontFamily: "Poppins",

                    px: 1,
                  }}
                >
                  Environment, Social
                </Typography>
                <Typography
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "100%",
                      lg: "100%",
                      xl: "100%",
                    },
                    textAlign: "center",
                    fontSize: {
                      xs: "1.8rem",
                      sm: "2rem",
                      md: "1.8rem",
                      lg: "2.5rem",
                      xl: "3rem",
                    },
                    fontWeight: "400",
                    color: "#ffffff",
                    fontFamily: "Poppins",

                    px: 1,
                  }}
                >
                  and Governance (ESG)
                </Typography>
                <Typography
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "100%",
                      lg: "100%",
                      xl: "100%",
                    },
                    textAlign: "center",
                    fontSize: {
                      xs: "1.8rem",
                      sm: "2rem",
                      md: "1.8rem",
                      lg: "2.5rem",
                      xl: "3rem",
                    },
                    fontWeight: "400",
                    color: "#ffffff",
                    fontFamily: "Poppins",

                    px: 1,
                  }}
                >
                  Risk Ratings
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "100%",
                      lg: "100%",
                      xl: "100%",
                    },
                    px: 2,
                  }}
                >
                  <SearchBar />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
          borderRadius: "0px",

          // backgroundColor: "#0B2E24",
          background:
            "transparent radial-gradient(circle closest-corner at 65% 60%, #00C28A 0%, #0B2E24 100%) 0% 0% no-repeat padding-box",
          width: "100%",
          height: {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "20rem",
            xl: "25rem",
          },
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "100%",
                      lg: "100%",
                      xl: "100%",
                    },
                    ml: {
                      xs: 0,
                      sm: 0,
                      md: 0,
                      lg: 15,
                      xl: 15,
                    },
                    fontSize: {
                      xs: "1.5rem",
                      sm: "2rem",
                      md: "1.8rem",
                      lg: "2rem",
                      xl: "3rem",
                    },
                    fontWeight: "400",
                    color: "#ffffff",
                    fontFamily: "Poppins",
                    mt: 5,
                  }}
                >
                  Environment, Social
                </Typography>
                <Typography
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "100%",
                      lg: "100%",
                      xl: "100%",
                    },
                    ml: {
                      xs: 0,
                      sm: 0,
                      md: 0,
                      lg: 15,
                      xl: 15,
                    },
                    fontSize: {
                      xs: "1.5rem",
                      sm: "2rem",
                      md: "1.8rem",
                      lg: "2rem",
                      xl: "3rem",
                    },
                    fontWeight: "400",
                    color: "#ffffff",
                    fontFamily: "Poppins",
                  }}
                >
                  and Governance (ESG)
                </Typography>
                <Typography
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "100%",
                      lg: "100%",
                      xl: "100%",
                    },
                    ml: {
                      xs: 0,
                      sm: 0,
                      md: 0,
                      lg: 15,
                      xl: 15,
                    },
                    fontSize: {
                      xs: "1.5rem",
                      sm: "2rem",
                      md: "1.8rem",
                      lg: "2rem",
                      xl: "3rem",
                    },
                    fontWeight: "400",
                    color: "#ffffff",
                    fontFamily: "Poppins",
                  }}
                >
                  Risk Ratings
                </Typography>
                <Button
                  variant="outlined"
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "flex",
                      lg: "flex",
                      xl: "flex",
                    },
                    mt: {
                      xs: 0,
                      sm: 0,
                      md: 2.5,
                      lg: 2.5,
                      xl: 2.5,
                    },
                    ml: {
                      xs: 0,
                      sm: 0,
                      md: 0,
                      lg: 15,
                      xl: 15,
                    },
                    width: "8rem",
                    color: "#fff",
                    backgroundColor: "rgba(4, 120, 87, 1)",
                    border: "2px solid #00B47F",
                    transition: "all 0.3s linear",
                    "&:hover": {
                      backgroundColor: "rgba(0, 180, 127, 1)",
                      color: "#fff",
                    },

                    textTransform: "none",
                  }}
                  onClick={() => {
                    // window.location.href = "https://yodaplus.com/contact.html";
                    window.open("https://yodaplus.com/contact.html");
                  }}
                >
                  Contact Us
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                height: {
                  xs: "100%",
                  sm: "100%",
                  md: "15rem",
                  lg: "20rem",
                  xl: "25rem",
                },
              }}
            >
              <Box component="img" src={ESGImage} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              flexDirection: "column",
              pl: "2rem",
              width: "100%",
            }}
          >
            <DesktopFilterCard />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
                xl: "none",
              },
              justifyContent: "center",
            }}
          >
            {focused_indice ? <MobileDashboardContentCard /> : <Loading />}
          </Box>
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              pr: "2rem",
            }}
          >
            {focused_indice ? <MainDashboardContentCard /> : <Loading />}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ESGDashboard;
