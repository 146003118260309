import API from "../axiosConfig";

export const getFilters = async (params) => {
  const response = await API.get(
    `sustainability/filterlist/?selected_indices=${params}`
  );
  if (response.status === 200) {
    // console.log(response.data, "filter data");
    return response.data;
  } else {
    throw new Error("Error fetching filters");
  }
};

export const getSearchData = async (params) => {
  const response = await API.get(`search/search_company/?query=${params}`);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("Error fetching search data");
  }
};
