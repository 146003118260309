import { configureStore } from "@reduxjs/toolkit";
import indiceSlice from "./reducers/indices";
import stockList from "./reducers/stockList";
import reactotron from "../ReactotronConfig";
import filterSlice from "./reducers/filterList";
import companyDetailsSlice from "./reducers/companyDetails";
export default configureStore({
  reducer: {
    indiceList: indiceSlice,
    stockList: stockList,
    filterList: filterSlice,
    companyDetails: companyDetailsSlice,
  },
  enhancers: [reactotron.createEnhancer()],
});
