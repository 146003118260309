import Reactotron from "reactotron-react-js";
import { reactotronRedux } from "reactotron-redux";

Reactotron.configure() // we can use plugins here -- more on this later
  .connect(); // let's connect!

const reactotron = Reactotron.configure({ name: "React Native Demo" })
  .use(reactotronRedux()) //  <- here i am!
  .connect(); //Don't forget about me!

export default reactotron;
