import API from "../axiosConfig";

export const getIndices = async () => {
  const response = await API.get("sustainability/indicelist/");
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("Error fetching indices");
  }
};

export const getESGData = async (params) => {
  // console.log("API Prams", params);
  if (params.industryFilter === null) {
    // console.log("Industry Filter is null");
  }
  if (params.ratingFilter === null) {
    // console.log("Rating Filter is null");
  }
  const response = await API.get(
    `sustainability/stocklist/?page_number=${
      params.pagination
    }&requested_indice=${params.filterIndice}${
      params.industryFilter !== null && params.industryFilter !== ""
        ? "&industry_filter=" + params.industryFilter.replace("&", "%26")
        : ""
    }${
      params.ratingFilter !== null && params.ratingFilter !== ""
        ? "&rating_filter=" + params.ratingFilter
        : ""
    } &from_date_for_trend=2020-01-01`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("Error fetching esg data");
  }
};
