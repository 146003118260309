import API from "../axiosConfig";

export const getCompanyDetails = async (params) => {
  return await API.get(`sustainability/company-detail-esg/${params}/`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error.response.data;
      // return ;
    });
  // if (response.status === 200) {
  // } else {
  //   return "dsd";
  //   // throw new Error("Error fetching company details");
  // }
};

export const getRSSFeed = async () => {
  const response = await API.get(`news/`);
  if (response.status === 200) {
    return response.data.data;
  } else {
    throw new Error("Error fetching rss feed");
  }
};
