import React from "react";
import { Box } from "@mui/material";
import "../Footer.css";
import "../Footer.bootstrap.css";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube,
  FaMedium,
  FaGithub,
} from "react-icons/fa";
import Logo from "../assets/yodaplus-ESG-Logo-with-tagline.svg";
import contactImage from "../assets/contact-img.webp";

const currentYear = new Date().getFullYear();

const PageFooter = () => {
  return (
    <>
      <div class="bg-white">
        <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div class="bg-green-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
            <div class="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
              <div class="lg:self-center">
                <h2 class="text-3xl font-extrabold text-white sm:text-4xl">
                  <span class="block">Starting an ESG Project?</span>

                  <span class="block">Get in touch with us</span>
                </h2>

                <p class="mt-4 text-lg leading-6 text-gray-50">
                  Send us your requirements, and get connected with one of our
                  solution experts.
                </p>

                <a
                  href="https://yodaplus.com/contact.html"
                  class="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-green-700 hover:bg-green-50 hover:no-underline"
                >
                  Contact Us
                </a>
              </div>
            </div>

            <div class="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
              <img
                class="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                src={contactImage}
                alt="Yodaplus Contact Us Banner"
              />
            </div>
          </div>
        </div>
      </div>
      <Box
        sx={{
          background: "#E4F3E4 !important",
        }}
      >
        <footer class="bg-white">
          <div class="container">
            <div class="row top-footer">
              <div class="col-md-3 px-5">
                <h4>Services</h4>
                <ul>
                  <li>
                    <a href="https://yodaplus.com/enterprise-data-management.html">
                      Enterprise Data Management
                    </a>
                  </li>
                  <li>
                    <a href="https://yodaplus.com/blockchain.html">
                      Blockchain
                    </a>
                  </li>
                  <li>
                    <a href="https://yodaplus.com/capital-markets-technology.html">
                      Financial Technology
                    </a>
                  </li>
                  <li>
                    <a href="https://yodaplus.com/e-commerce.html">eCommerce</a>
                  </li>
                </ul>
              </div>
              <div class="col-md-3 px-5">
                <h4>Products</h4>
                <ul>
                  <li>
                    <a href="https://yodaplus.com/multisig-wallet.html">
                      Yplusvault
                    </a>
                  </li>
                  <li>
                    <a href="https://yodaplus.com/asset-tokenization.html">
                      ATP
                    </a>
                  </li>
                  <li>
                    <a href="https://yodaplus.com/defi-lending-pool.html">
                      Defi Lending Pool
                    </a>
                  </li>
                </ul>
              </div>

              <div class="col-md-3 px-5">
                <h4>Company</h4>
                <ul>
                  <li>
                    <a href="https://yodaplus.com/about.html">About us</a>
                  </li>
                  <li>
                    <a href="https://yodaplus.com/careers.html">Career</a>
                  </li>
                  <li>
                    <a href="https://yodaplus.com/contact.html">Contact</a>
                  </li>
                </ul>
              </div>
              <div class="col-md-3 px-5">
                <h4>Resources</h4>
                <ul>
                  <li>
                    <a
                      href="https://esg.yodaplus.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ESG
                    </a>
                  </li>
                  <li>
                    <a href="https://yodaplus.com/blog/">Blogs</a>
                  </li>
                  <li>
                    <a href="https://yodaplus.com/case-studies/">
                      Case Studies
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row bottom-footer">
              <div class="col-md-2">
                <img src={Logo} alt="Yodaplus Logo" />
              </div>
              <div class="col-md-4 d-inline-flex">
                {/* <a
                  href="https://m.facebook.com/yodaplustech/"
                  target="_blank"
                  style={{
                    color: "#000",
                    marginLeft: "0.3rem",
                    marginRight: "0.3rem",
                    "@media (max-width: 768px)": {
                      marginLeft: "1rem",
                      marginRight: "1rem",
                    },
                  }}
                > */}
                {/* <span class="sr-only">Facebook</span>
                  <span
                    style={{
                      fontSize: "1rem",
                      border: "1px solid #000",
                      borderRadius: "50%",
                      width: "2rem",
                      height: "2rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",

                      "&:hover": {
                        color: "#FFFFFF",
                        backgroundColor: "#047857",
                      },
                    }} */}
                {/* > */}
                {/* <!-- Heroicon name: outline/pencil-alt --> */}
                {/* <i class="fab fa-facebook-f" aria-hidden="true"></i> */}
                {/* <FaFacebookF /> */}
                {/* </span> */}
                {/* </a> */}

                <a
                  href="https://www.linkedin.com/company/yodaplus/"
                  target="_blank"
                  style={{
                    color: "#000",
                    marginLeft: "0.3rem",
                    marginRight: "0.3rem",
                    "@media (max-width: 768px)": {
                      marginLeft: "1rem",
                      marginRight: "1rem",
                    },
                  }}
                >
                  <span class="sr-only">LinkedIn</span>
                  <span
                    style={{
                      fontSize: "1rem",
                      border: "1px solid #000",
                      borderRadius: "50%",
                      width: "2rem",
                      height: "2rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",

                      "&:hover": {
                        color: "#FFFFFF",
                        backgroundColor: "#047857",
                      },
                    }}
                  >
                    {/* <!-- Heroicon name: outline/pencil-alt --> */}
                    {/* <i class="fab fa-linkedin-in" aria-hidden="true"></i> */}
                    <FaLinkedinIn />
                  </span>
                </a>

                <a
                  href="https://twitter.com/yodaplustech"
                  target="_blank"
                  style={{
                    color: "#000",
                    marginLeft: "0.3rem",
                    marginRight: "0.3rem",
                    "@media (max-width: 768px)": {
                      marginLeft: "1rem",
                      marginRight: "1rem",
                    },
                  }}
                >
                  <span class="sr-only">Twitter</span>
                  <span
                    style={{
                      fontSize: "1rem",
                      border: "1px solid #000",
                      borderRadius: "50%",
                      width: "2rem",
                      height: "2rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",

                      "&:hover": {
                        color: "#FFFFFF",
                        backgroundColor: "#047857",
                      },
                    }}
                  >
                    {/* <!-- Heroicon name: outline/pencil-alt --> */}
                    {/* <i class="fab fa-twitter" aria-hidden="true"></i> */}
                    <FaTwitter />
                  </span>
                </a>

                <a
                  href="https://www.youtube.com/channel/UCQJkybs9OYhCnKnyCUMYo6g"
                  target="_blank"
                  style={{
                    color: "#000",
                    marginLeft: "0.3rem",
                    marginRight: "0.3rem",
                    "@media (max-width: 768px)": {
                      marginLeft: "1rem",
                      marginRight: "1rem",
                    },
                  }}
                >
                  <span class="sr-only">Youtube</span>
                  <span
                    style={{
                      fontSize: "1rem",
                      border: "1px solid #000",
                      borderRadius: "50%",
                      width: "2rem",
                      height: "2rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",

                      "&:hover": {
                        color: "#FFFFFF",
                        backgroundColor: "#047857",
                      },
                    }}
                  >
                    {/* <!-- Heroicon name: outline/pencil-alt --> */}
                    {/* <i class="fab fa-youtube" aria-hidden="true"></i> */}
                    <FaYoutube />
                  </span>
                </a>

                <a
                  href="https://medium.com/yodaplus"
                  target="_blank"
                  style={{
                    color: "#000",
                    marginLeft: "0.3rem",
                    marginRight: "0.3rem",
                    "@media (max-width: 768px)": {
                      marginLeft: "1rem",
                      marginRight: "1rem",
                    },
                  }}
                >
                  <span class="sr-only">Medium</span>
                  <span
                    style={{
                      fontSize: "1rem",
                      border: "1px solid #000",
                      borderRadius: "50%",
                      width: "2rem",
                      height: "2rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",

                      "&:hover": {
                        color: "#FFFFFF",
                        backgroundColor: "#047857",
                      },
                    }}
                  >
                    {/* <!-- Heroicon name: outline/pencil-alt --> */}
                    {/* <i class="fab fa-medium" aria-hidden="true"></i> */}
                    <FaMedium />
                  </span>
                </a>
                <a
                  href="https://github.com/yodaplus/"
                  target="_blank"
                  style={{
                    color: "#000",
                    marginLeft: "0.3rem",
                    marginRight: "0.3rem",
                    "@media (max-width: 768px)": {
                      marginLeft: "1rem",
                      marginRight: "1rem",
                    },
                  }}
                >
                  <span class="sr-only">Github</span>
                  <span
                    style={{
                      fontSize: "1rem",
                      border: "1px solid #000",
                      borderRadius: "50%",
                      width: "2rem",
                      height: "2rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",

                      "&:hover": {
                        color: "#FFFFFF",
                        backgroundColor: "#047857",
                      },
                    }}
                  >
                    {/* <!-- Heroicon name: outline/pencil-alt --> */}
                    {/* <i aria-hidden="true" class="fab fa-github"></i> */}
                    <FaGithub />
                  </span>
                </a>
              </div>
              <div class="col-md-6">
                <p class="copyright">
                  © {currentYear} – Yodaplus. All right reserved.
                </p>
              </div>
            </div>
          </div>
        </footer>
      </Box>
    </>
  );
};

export default PageFooter;
