import React, { useState, useRef } from "react";
import {
  Card,
  CardContent,
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  List,
  ListItemText,
  Checkbox,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setIndustryFilterValue,
  setRatingFilterValue,
  setChecked,
  setCheckedRating,
} from "../store/reducers/filterList";
import {
  setStockListLoading,
  setResetFilters,
  setPageNumber,
} from "../store/reducers/stockList";
import { useSnackbar } from "notistack";
// import SearchBar from "./SearchBar";
import { MdExpandMore } from "react-icons/md";

const DesktopFilterCard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  // const [checked, setChecked] = useState([0]);
  // const [checkedRating, setCheckedRating] = useState([0]);

  const focused_indice = useSelector(
    (state) => state.indiceList.focused_indice
  );
  const industry_filter_list = useSelector(
    (state) => state.filterList.industry_filter_list
  );
  const rating_filter_list = useSelector(
    (state) => state.filterList.rating_filter_list
  );
  const selected_industry_filter = useSelector(
    (state) => state.filterList.selected_industry_filter
  );
  const selected_rating_filter = useSelector(
    (state) => state.filterList.selected_rating_filter
  );
  const checked = useSelector((state) => state.filterList.checked);
  const checkedRating = useSelector((state) => state.filterList.checkedRating);
  // console.log("checked", checked);
  // console.log("checkedRating", checkedRating);

  const industry_ref = useRef(null);
  const rating_ref = useRef(null);

  const handleIndustryFilterChange = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    // console.log("The Industry target value is ", newChecked);
    dispatch(setChecked({ checked: newChecked }));
    // setChecked(newChecked);
    const apiParam = newChecked.slice(1);
    const quotedParam = apiParam.map((item) => `'${item}'`);
    const joinedParam = quotedParam.join(",");
    // console.log("The param is ", joinedParam);
    dispatch(setIndustryFilterValue({ selected_industry_filter: joinedParam }));
    dispatch(setStockListLoading({ stock_list_loading: true }));
    dispatch(setPageNumber({ page_number: 1 }));
    // console.log("selected industry filter", selected_industry_filter);
  };
  // console.log("selected industry filter", selected_industry_filter);

  const handleRatingFilterChange = (value) => {
    const currentIndex = checkedRating.indexOf(value);
    const newChecked = [...checkedRating];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    // console.log("The Rating target value is ", newChecked);

    // setCheckedRating(newChecked);
    dispatch(setCheckedRating({ checkedRating: newChecked }));
    const apiParam = newChecked.slice(1);
    const quotedParam = apiParam.map((item) => `'${item}'`);
    const joinedParam = quotedParam.join(",");
    // console.log("The param is ", joinedParam);
    dispatch(setRatingFilterValue({ selected_rating_filter: joinedParam }));
    dispatch(setStockListLoading({ stock_list_loading: true }));
    dispatch(setPageNumber({ page_number: 1 }));
  };

  const resetFilters = () => {
    dispatch(setChecked({ checked: [0] }));
    dispatch(setCheckedRating({ checkedRating: [0] }));
    // setChecked([0]);
    // setCheckedRating([0]);
    if (selected_industry_filter != null || selected_rating_filter != null) {
      dispatch(setIndustryFilterValue({ selected_industry_filter: null }));
      dispatch(setRatingFilterValue({ selected_rating_filter: null }));
      dispatch(setStockListLoading({ stock_list_loading: true }));
      dispatch(setResetFilters({ reset_filters: true }));
      dispatch(setPageNumber({ page_number: 1 }));
    }
  };

  return (
    <Card
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        mt: 5,
        elevation: 0,
        p: 2,
        justifyContent: "center",
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2)",
        borderRadius: "2px",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            mb: 2,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "bold",
              fontSize: "1.2rem",
              color: "#000000",

              textAlign: "start",
            }}
          >
            FILTERS
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "bold",
              fontSize: "0.8rem",
              color: "#047857",
              cursor: "pointer",
              "&:hover": {
                color: "#1EB155",
              },
            }}
            onClick={resetFilters}
          >
            Reset
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#e6f1e6",
                borderRadius: "10px",
                elevation: 0,
                flexDirection: "column",
              }}
            >
              <Accordion
                sx={{
                  display: "flex",
                  width: "100%",
                  backgroundColor: "#e6f1e6",
                  borderRadius: "10px",
                  elevation: 0,
                  flexDirection: "column",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        fontSize: "1.5rem",
                      }}
                    >
                      <MdExpandMore />
                    </Box>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    display: "flex",
                    width: "100%",
                    // "& .MuiAccordionSummary-root": {
                    //   " & .Mui-expanded": {
                    //     minHeight: "100px",
                    //     height: "150px",
                    //     maxHeight: "200px",
                    //   },
                    // },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      textTransform: "none",
                      fontSize: "1rem",
                    }}
                  >
                    Select Industry
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Divider />
                  <List
                    sx={{
                      width: "100%",

                      bgcolor: "#e6f1e6",
                      maxHeight: "400px",
                      overflow: "auto",
                      scrollbarColor: "#047857 #f1f1f1",
                      scrollbarWidth: "0.4rem",
                      "&::-webkit-scrollbar": {
                        width: "0.4rem",
                      },
                      "&::-webkit-scrollbar-track": {
                        background: "#f1f1f1",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        background: "#047857",
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        background: "#555",
                      },
                      "&::-webkit-scrollbar:horizontal": {
                        display: "none",
                        height: 0,
                        width: 0,
                      },
                    }}
                  >
                    {industry_filter_list.map((industry) => {
                      const labelId = `checkbox-list-label-${industry}`;
                      return (
                        <ListItemButton
                          key={industry}
                          role={undefined}
                          dense
                          button
                          onClick={() => {
                            handleIndustryFilterChange(industry);
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              m: 0,
                              p: 0,
                              minWidth: "0px",
                            }}
                          >
                            <Checkbox
                              style={{ color: "#28C62B" }}
                              edge="start"
                              checked={checked.indexOf(industry) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={industry}
                            primaryTypographyProps={{
                              fontSize: {
                                xs: "0.8rem",
                                sm: "0.8rem",
                                md: "0.6rem",
                                lg: "0.8rem",
                                xl: "0.8rem",
                              },
                              fontFamily: "Poppins",
                            }}
                          />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#e6f1e6",
                borderRadius: "10px",
                elevation: 0,
                flexDirection: "column",
              }}
            >
              <Accordion
                sx={{
                  display: "flex",
                  width: "100%",
                  backgroundColor: "#e6f1e6",
                  borderRadius: "10px",
                  elevation: 0,
                  flexDirection: "column",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        fontSize: "1.5rem",
                      }}
                    >
                      <MdExpandMore />
                    </Box>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      textTransform: "none",
                      fontSize: "1rem",
                    }}
                  >
                    Select Rating
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Divider />
                  <List
                    sx={{ width: "100%", maxWidth: 360, bgcolor: "#e6f1e6" }}
                  >
                    {rating_filter_list.map((rating) => {
                      const labelId = `checkbox-list-label-${rating}`;
                      return (
                        <ListItemButton
                          key={rating}
                          role={undefined}
                          dense
                          button
                          onClick={() => {
                            handleRatingFilterChange(rating);
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              m: 0,
                              p: 0,
                              minWidth: "0px",
                            }}
                          >
                            <Checkbox
                              edge="start"
                              checked={checkedRating.indexOf(rating) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                              style={{ color: "#28C62B" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={rating}
                            primaryTypographyProps={{
                              fontSize: {
                                xs: "0.8rem",
                                sm: "0.8rem",
                                md: "0.8rem",
                                lg: "0.8rem",
                                xl: "0.8rem",
                              },
                              fontFamily: "Poppins",
                            }}
                          />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          {/* <Grid
            item
            xs={0}
            sm={0}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <SearchBar />
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DesktopFilterCard;
