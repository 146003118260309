import React from "react";
import {
  Legend,
  ResponsiveContainer,
  Tooltip,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import { Box, Card, CardContent, Typography } from "@mui/material";

const ESGSharePriceChart = ({ chart_data, currency }) => {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: {
            xs: "1.2rem",
            sm: "1.5rem",
            md: "1.5rem",
            lg: "1.5rem",
            xl: "1.5rem",
          },
          fontFamily: "Poppins",
          fontWeight: "bold",
          color: "#047857",
          textTransform: "uppercase",
        }}
      >
        ESG vs Share Price
      </Typography>
      <Box
        sx={{
          display: {
            xs: "flex",
            sm: "flex",
            md: "flex",
            lg: "none",
            xl: "none",
          },
        }}
      >
        {chart_data?.length > 0 ? (
          <ResponsiveContainer
            className="watermark"
            aspect={1.7}
            width="100%"
            height="100%"
          >
            <ComposedChart data={chart_data}>
              <XAxis
                dataKey="date"
                tickFormatter={(value) => {
                  return value.substring(0, 7);
                }}
                tickCount={chart_data.length}
                // label={{
                //   value: "Date",
                //   style: { textAnchor: "middle" },

                //   position: "bottom",
                //   offset: -2,
                // }}
              />
              <YAxis
                yAxisId="left-axis"
                reversed={true}
                label={{
                  value: "ESG Score",
                  style: { textAnchor: "middle" },
                  angle: -90,
                  position: "left",
                  offset: -1,
                }}
              />
              <YAxis
                yAxisId="right-axis"
                orientation="right"
                label={{
                  value: `Share Price (${currency})`,
                  style: { textAnchor: "middle" },
                  angle: 90,
                  position: "right",
                  offset: -1,
                }}
              />
              <Tooltip
                formatter={(value) => {
                  return value.toFixed(2);
                }}
                labelFormatter={(value) => {
                  return value.substring(0, 7);
                }}
              />
              <Legend />
              <CartesianGrid stroke="#f5f5f5" />
              <Line
                yAxisId="left-axis"
                type="number"
                name="Total ESG"
                dataKey="total_esg"
                stroke="#ff7300"
                reversed={true}
              />
              <Line
                yAxisId="right-axis"
                type="monotone"
                name="Stock Closing Price"
                dataKey="stock_closing_price"
                stroke="#413ea0"
              />
            </ComposedChart>
          </ResponsiveContainer>
        ) : null}
      </Box>
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "flex",
            xl: "flex",
          },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "65%",
          }}
        >
          {chart_data?.length > 0 ? (
            <ResponsiveContainer
              className="watermark"
              aspect={1.7}
              width="100%"
              height="100%"
            >
              <ComposedChart data={chart_data}>
                <XAxis
                  dataKey="date"
                  tickFormatter={(value) => {
                    return value.substring(0, 7);
                  }}
                  tickCount={chart_data.length}
                  // label={{
                  //   value: "Date",
                  //   style: { textAnchor: "middle" },

                  //   position: "bottom",
                  //   offset: -2,
                  // }}
                />
                <YAxis
                  yAxisId="left-axis"
                  reversed={true}
                  label={{
                    value: "ESG Score",
                    style: { textAnchor: "middle" },
                    angle: -90,
                    position: "left",
                    offset: -1,
                  }}
                />
                <YAxis
                  yAxisId="right-axis"
                  orientation="right"
                  label={{
                    value: `Share Price (${currency})`,
                    style: { textAnchor: "middle" },
                    angle: 90,
                    position: "right",
                    offset: -1,
                  }}
                />
                <Tooltip
                  formatter={(value) => {
                    return value.toFixed(2);
                  }}
                  labelFormatter={(value) => {
                    return value.substring(0, 7);
                  }}
                />
                <Legend />
                <CartesianGrid stroke="#f5f5f5" />
                <Line
                  yAxisId="left-axis"
                  type="number"
                  name="Total ESG"
                  dataKey="total_esg"
                  stroke="#ff7300"
                  reversed={true}
                />
                <Line
                  yAxisId="right-axis"
                  type="monotone"
                  name="Stock Closing Price"
                  dataKey="stock_closing_price"
                  stroke="#413ea0"
                />
              </ComposedChart>
            </ResponsiveContainer>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default ESGSharePriceChart;
