import React from "react";
import { Box, Autocomplete, TextField, InputAdornment } from "@mui/material";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { getSearchData } from "../service/API/FilterAPI";
import { useDispatch, useSelector } from "react-redux";
import { setSearchData } from "../store/reducers/filterList";
import {
  setCompanyDetailsData,
  setTrendChartData,
  setCompanyDetailsLoading,
  setCompanyDetailSlug,
} from "../store/reducers/companyDetails";
import { CLIENT_BASE_URL, SERVER_TYPE } from "../esg_project_config";
import { useSnackbar } from "notistack";
const SearchBar = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const search_data = useSelector((state) => state.filterList.search_data);

  const handleSearchChange = (event) => {
    // console.log(event.target.value);
    if (event.target.value.length > 2) {
      getSearchData(event.target.value)
        .then((response) => {
          // console.log(response.data, "========================");
          dispatch(
            setSearchData({ search_data: response.data.search_results })
          );
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  const handleSearchSubmit = (event, value) => {
    try {
      dispatch(setCompanyDetailsData({ company_details_data: [] }));
      dispatch(setTrendChartData({ trend_chart_data: [] }));
      dispatch(setCompanyDetailsLoading({ company_details_loading: true }));
      dispatch(setCompanyDetailSlug({ company_slug: value.company__slug }));
      // console.log("The Slug", value.company__slug);
      if (value.company__slug === undefined) {
        enqueueSnackbar("Invalid Search", {
          variant: "error",
        });
      } else {
        if (SERVER_TYPE !== "local") {
          window.location.href = `${CLIENT_BASE_URL}esg-risk-ratings/${value.company__slug}`;
        } else {
          navigate(`/esg-risk-ratings/${value.company__slug}`);
        }
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };
  return (
    <Autocomplete
      size={
        window.innerWidth < 600
          ? "small"
          : window.innerWidth < 960
          ? "small"
          : "small"
      }
      freeSolo
      id="combo-box-demo"
      options={search_data}
      getOptionLabel={(option) =>
        option.identifier === undefined
          ? ""
          : `(${option.identifier}) ${option.company__name}`
      }
      onChange={handleSearchSubmit}
      autoHighlight
      sx={{
        width: {
          xs: "100%",
          sm: "100%",
          md: "100%",
          lg: "100%",
          xl: "100%",
        },
        fontFamily: "Poppins",
        background: {
          xs: window.location.href.includes("esg-risk-ratings")
            ? "#FFFFFF"
            : "#1A7D60",
          sm: "#FFFFFF",
          md: "#FFFFFF",
          lg: "#FFFFFF",
          xl: "#FFFFFF",
        },
        borderRadius: {
          xs: "7px",
          sm: "7px",
          md: "5px",
          lg: "5px",
          xl: "5px",
        },
      }}
      renderInput={(params) => (
        <TextField
          size="small"
          {...params}
          sx={{
            fontFamily: "Poppins",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "1px solid #CBE1CB",
              },
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              "& > fieldset": {
                borderColor: "#06AF7F",
              },
            },

            "& .MuiFormLabel-root.Mui-focused": {
              color: "#FFFFFF",
            },
            "@media (max-width: 600px)": window.location.href.includes(
              "esg-risk-ratings"
            )
              ? {
                  "& .MuiInputBase-root": {
                    color: "#9A9C9E",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#9A9C9E",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#047857",
                  },
                }
              : {
                  "& .MuiInputBase-root": {
                    color: "#FFFFFF",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#FFFFFF",
                  },
                },

            "@media (min-width: 600px)": {
              "& .MuiInputBase-root": {
                color: "#000000",
              },
              "& .MuiFormLabel-root": {
                color: "#9A9C9E",
              },
              "& .MuiFormLabel-root.Mui-focused": {
                color: "#9A9C9E",
              },
            },
            "@media (min-width: 960px)": {
              "& .MuiInputBase-root !important": {
                color: "#000000",
              },
              "& .MuiFormLabel-root !important": {
                color: "#000000",
              },
              "& .MuiFormLabel-root.Mui-focused": {
                color: "#9A9C9E",
              },
            },

            color: {
              xs: "#FFFFFF",
              sm: "#CBE1CB",
              md: "#CBE1CB",
              lg: "#CBE1CB",
              xl: "#CBE1CB",
            },
            borderRadius: "0px",
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                    color: {
                      xs: window.location.href.includes("esg-risk-ratings")
                        ? "#9A9C9E"
                        : "#FFFFFF",
                      sm: "#CBE1CB",
                      md: "#CBE1CB",
                      lg: "#CBE1CB",
                      xl: "#CBE1CB",
                    },
                  }}
                >
                  <BsSearch />
                </Box>
              </InputAdornment>
            ),
            style: {
              fontFamily: "Poppins",
              color: {
                xs: "#FFFFFF",
                sm: "#CBE1CB",
                md: "#CBE1CB",
                lg: "#CBE1CB",
                xl: "#CBE1CB",
              },
              // height: "40px",
            },
          }}
          onChange={handleSearchChange}
          label="Search for Companies"
          InputLabelProps={{
            fontFamily: "Poppins",
            // height: "20px",
          }}
        />
      )}
    />
  );
};

export default SearchBar;
