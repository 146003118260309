import { createSlice } from "@reduxjs/toolkit";

export const stockListSlice = createSlice({
  name: "stockList",
  initialState: {
    page_number: 1,
    stock_list_data: [],
    stock_list_loading: true,
    reset_filters: false,
    max_pages: 1,
  },
  reducers: {
    setPageNumber: (state, action) => {
      state.page_number = action.payload.page_number;
    },
    setStockListdata: (state, action) => {
      state.stock_list_data = action.payload.stock_list_data;
    },

    setMaxPageValue: (state, action) => {
      state.max_pages = action.payload.max_pages;
    },
    setStockListLoading: (state, action) => {
      state.stock_list_loading = action.payload.stock_list_loading;
    },
    setResetFilters: (state, action) => {
      state.reset_filters = action.payload.reset_filters;
    },
  },
});

export const {
  setPageNumber,
  setStockListdata,
  setMaxPageValue,
  setStockListLoading,
  setResetFilters,
} = stockListSlice.actions;

export default stockListSlice.reducer;
