import { createSlice } from "@reduxjs/toolkit";

export const filterSlice = createSlice({
  name: "filterList",
  initialState: {
    industry_filter_list: [],
    rating_filter_list: [],
    esgc_filter_list: {},
    selected_industry_filter: null,
    selected_rating_filter: null,
    selected_esgc_filter: null,
    checked: [0],
    checkedRating: [0],
    search_data: [],
  },
  reducers: {
    setIndustryFilterValue: (state, action) => {
      state.selected_industry_filter = action.payload.selected_industry_filter;
    },
    setRatingFilterValue: (state, action) => {
      state.selected_rating_filter = action.payload.selected_rating_filter;
    },
    setESGCFilterValue: (state, action) => {
      state.selected_esgc_filter = action.payload.selected_esgc_filter;
    },
    setIndustryFilterList: (state, action) => {
      state.industry_filter_list = action.payload.industry_filter_list;
    },
    setRatingFilterList: (state, action) => {
      state.rating_filter_list = action.payload.rating_filter_list;
    },
    setESGCFilterList: (state, action) => {
      state.esgc_filter_list = action.payload.esgc_filter_list;
    },
    setSearchData: (state, action) => {
      // console.log(action.payload.search_data, "search data");
      state.search_data = action.payload.search_data;
    },
    setChecked: (state, action) => {
      state.checked = action.payload.checked;
    },
    setCheckedRating: (state, action) => {
      state.checkedRating = action.payload.checkedRating;
    },
  },
});

export const {
  setIndustryFilterValue,
  setRatingFilterValue,
  setIndustryFilterList,
  setRatingFilterList,
  setESGCFilterValue,
  setESGCFilterList,
  setSearchData,
  setChecked,
  setCheckedRating,
} = filterSlice.actions;

export default filterSlice.reducer;
