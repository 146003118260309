import { createSlice } from "@reduxjs/toolkit";

export const indiceSlice = createSlice({
  name: "indiceList",
  initialState: {
    country_list: [],
    focused_indice: null,
    focused_country: null,
    focused_country_name: null,
    focused_country_flag: null,
    indice_list: [],
    focused_currency: null,
  },
  reducers: {
    setIndicesList: (state, action) => {
      let updated_indice_list = [...action.payload.country_list];
      state.country_list = updated_indice_list;
    },
    setFocusedIndice: (state, action) => {
      state.focused_indice = action.payload.focused_indice;
    },
    setFocusedCountry: (state, action) => {
      let updated_indice_list;
      if (action.payload.focused_country !== "all") {
        state.country_list.map((country) => {
          if (country.id === action.payload.focused_country) {
            updated_indice_list = country.indices;
          }
        });
      } else {
        updated_indice_list = [];
      }
      state.focused_country = action.payload.focused_country;
      state.indice_list = updated_indice_list;
    },
    setFocusedCountryName: (state, action) => {
      state.focused_country_name = action.payload.focused_country_name;
    },
    setFocusedCountryFlag: (state, action) => {
      state.focused_country_flag = action.payload.focused_country_flag;
    },
    setFocusedCurrency: (state, action) => {
      state.focused_currency = action.payload.focused_currency;
    },
  },
});

export const {
  setIndicesList,
  setFocusedIndice,
  setFocusedCountry,
  setFocusedCurrency,
  setFocusedCountryName,
  setFocusedCountryFlag,
} = indiceSlice.actions;

export default indiceSlice.reducer;
